import React from "react";
import "./Footer.css";
import logo from "../../img/footer/logo.svg";
import fb from "../../img/footer/facebook.svg";
import ln from "../../img/footer/linkedin.svg";
import twitter from "../../img/footer/twitter.svg";
import inst from "../../img/footer/instagram.svg";
import yb from "../../img/footer/youtube.svg";

export default class Footer extends React.Component {
  render() {
      return (
          <div className='footer_wrap'>
              <div className='container footer'>
                  <div className='footer_links footer_item'>
                    <a rel="noreferrer" className='footer_link_geekle' href="https://geekle.us/" target='_blank'>
                      <img className='footer_geekle_logo' src={logo} alt="Geekle logo" />
                    </a>
                    <div className='footer_link_block'>
                      <a rel="noreferrer" className='footer_link footer_link--ln' target='_blank' href='https://www.linkedin.com/company/34674119/admin/'>
                        <img className='footer_link_img' src={ln} alt="Geekle linkedin" />
                      </a>
                      <a rel="noreferrer" className='footer_link footer_link--fb' target='_blank' href='https://www.facebook.com/geekleclubs/'>
                        <img className='footer_link_img' src={fb} alt="Geekle facebook" />
                      </a>
                      <a rel="noreferrer" className='footer_link footer_link--twitter' target='_blank' href='https://twitter.com/GeekleOfficial'>
                        <img className='footer_link_img' src={twitter} alt="Geekle twitter" />
                      </a>
                      <a rel="noreferrer" className='footer_link footer_link--inst' target='_blank' href='https://www.instagram.com/geekle.us/'>
                        <img className='footer_link_img' src={inst} alt="Geekle instagram" />
                      </a>
                      <a rel="noreferrer" className='footer_link footer_link--yb' target='_blank' href='https://www.youtube.com/channel/UCT-RTQuSWLsJXytEy7Q7n4g'>
                        <img className='footer_link_img' src={yb} alt="Geekle youtube" />
                      </a>
                    </div>
                  </div>
                  <div className='footer_address footer_item'>Geekle Corp. <br /> 910 Foulk Road, Suite 201 <br /> Wilmington, DE <br /> 19803, USA</div>
                  <div className='footer_contacts footer_item'>
                    <a rel="noreferrer" className='footer_phone footer_contact' href="tel:+16505342075" target='_blank'>+1 650 534 2075</a>
                    <a rel="noreferrer" className='footer_mail footer_contact' href="mailto:info@geekle.us" target='_blank'>info@geekle.us</a>
                  </div>
                  <div className='footer_docs footer_item'>
                    <a rel="noreferrer" className='footer_code footer_docs_item' href="https://geekle.us/code-of-conduct" target='_blank'>Code of Conduct</a>
                    <a rel="noreferrer" className='footer_legal footer_docs_item' href="https://geekle.us/legal-docs" target='_blank'>Legal Docs</a>
                  </div>
              </div>
          </div>
      )
  }
}
