import React from "react";
import './App.css';
import Header from './components/Header/Header.jsx';
import Main from './components/main/main.jsx';
import Agenda from './components/Agenda/Agenda.jsx';
import Numbers from './components/Numbers/Numbers.jsx';
import SpeakersFrom from './components/Speakers from/Speakers from.jsx';
import Audience from './components/Audience/Audience.jsx';
import Photos from './components/Photos/Photos.jsx';
import TagCloud from './components/TagCloud/TagCloud.jsx';
import Speakers from './components/Speakers/Speakers.jsx';
import Packages from './components/Packages/Packages.jsx';
import AskCompany from "./components/AskCompany/AskCompany";
import Enterprise from "./components/Enterprise/Enterprise";
import Workshops from "./components/Workshops/Workshops";
import Become from "./components/Become/Become";
import Sponsors from "./components/Sponsors/Sponsors";
import Partners from "./components/Partners/Partners";
import PC from "./components/PC/PC";
import Mission from "./components/Mission/Mission";
import Reviews from './components/Reviews/Reviews.jsx';
import Team from './components/Team/Team.jsx';
import Footer from './components/footer/Footer.jsx';
import Countdown from './components/CountDown/CountDown';

const App = (props) => {
  window.location.href = 'https://events.geekle.us/wsas4/';

  return (
    <main>
      <Header />
      <Main />
      <Numbers />
      <SpeakersFrom />
      <Audience />
      <Photos />
      <TagCloud />
      <Speakers />
      <Packages />
      <Agenda />
      <AskCompany />
      <Enterprise />
      <Become />
      <Workshops />
      <Sponsors />
      <Partners />
      <PC />
      <Reviews />
      <Team />
      <Mission />
      <Footer />
      <Countdown />
    </main>
  );
}

export default App;