import React from "react";
import "./Photos.css";
import TeamInfofrom from "../../store/TeamInfo.js";
import TeamChild from './Photo/Photo.jsx'

const Photos = () => {
  let teamElements = TeamInfofrom.map((teamItem, i) => {
    return <TeamChild key={`team-photo-${i}`}  team={teamItem} />;
  });
  
  return (
    <div className="team">{teamElements}</div>
  );
};
export default Photos;
