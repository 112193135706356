import React from 'react';
import './Workshops.css';
import Sunku from '../../img/workshops/Sunku.png';

export default class Workshops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workshops: [
        {
            id: 1,
            date: '2022, March 11, Duration 2 hours',
            photo: Sunku,
            name: 'Sunku Ranganath',
            position: 'Solutions Architect for Edge Compute at Intel',
            ln: 'https://www.linkedin.com/in/sunkuranganath/',
            logo: '',
            title: 'Edge Computing - Architectural Patterns, Industry Disruption & Innovation Opportunities',
            bookHref: 'https://events.geekle.us/workshops/SunkuRanganath#tickets',
            price: '$199',
            learnMore: 'https://events.geekle.us/workshops/SunkuRanganath/',
        },
      ]
    }
  }

  renderWorkshops(item, i) {
    return (
      <div className="workshops_item" key={`workshops-${i}`}>
        <div className='workshops_item-title_wrap'>
            <p className='workshops_item-title'>Workshop #{item.id}</p>
            <p className='workshops_item-date'>{item.date}</p>
        </div>
        <div className='workshops_item-info_wrap'>
            <div className='workshops_item-info' style={{ backgroundImage: `url(${item.photo})` }}>
                <p className="workshops_item_name">{item.name}</p>
                <p className="workshops_item_position">{item.position}</p>
                <div className='workshops_social_link'>
                  {item.tw && <a className='social_link social_tw' target='_blank' rel="noreferrer" href={item.tw}></a>}
                  {item.ln && <a className='social_link social_ln' target='_blank' rel="noreferrer" href={item.ln}></a>}
                  {item.fb && <a className='social_link social_fb' target='_blank' rel="noreferrer" href={item.fb}></a>}
                </div>
            </div>
            <div className='workshops_item-content'>
                <img className='workshops_company_logo' src={item.logo} alt="" />
                <p className='workshops_title'>"{item.title}"</p>
                <div className='workshops_buttons'>
                    <a className='workshops_button-book' target='_blank' rel="noreferrer" href={item.bookHref}>Book for {item.price}</a>
                    <a className='workshops_button-book workshops_button-learnMore' target='_blank' rel="noreferrer" href={item.learnMore}>Learn more</a>
                </div>
            </div>
        </div>
      </div>
    )
  }

  render() {
      return (
          <div className="ourTteam" id='workshops'>
              <h3>Workshops</h3>
              <div className='container team_wrap'>
                  <div className="workshops_block">
                    {this.state.workshops.map((item, i) => this.renderWorkshops(item, i))}
                  </div>
              </div> 
          </div>
      )
  }
}