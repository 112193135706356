let PackagesItem = [
    {
        id: 1,
        trackTitle: "PRO TRACK",
        trackSubtitle: "For Senior Software Architects, CTOs and Founders",
        trackPrice: "$199",
        trackDesc: "1",
        trackButton: "Register",
        trackFull: 'Full Access to all talks & Q&A sessions recordings',
        trackLink: 'https://geekle.us/ticket/1635169206308x184771132711239680',
    },
    {
        id: 2,
        trackTitle: "CAREER TRACK",
        trackSubtitle: "For Software Developers, Junior&Middle Software Architects",
        trackPrice: "FREE",
        trackButton: "Register",
        trackQA: 'Live Q&A panels from a Career track',
        trackLink: 'https://geekle.us/ticketfree/1635170109642x408251795026739200',
    },
  ];
  
  export default PackagesItem;
  