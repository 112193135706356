import React from 'react';
import './Header.css';
import Menu from './Menu/Menu.jsx';
import Logo from '../../img/logo.svg';

const Header = (props) => {
    return (
        <header className='header_container'>
            <div>
                <a href='https://geekle.us/'>
                    <img src={Logo} alt="Logotype" />
                </a>
            </div>
            <Menu
                speakers={props.speakers}
                workshops={props.workshops}
                sponsors={props.sponsors}
                pc={props.pc}
                team={props.team}
                packages={props.packages}
            />
            <div></div>
        </header>
    );
}
  
export default Header;