import React from 'react';
import ReactPlayer from "react-player";
import './VideoPresent.css';
import Popup from './Popup/Popup.jsx'
import Miletic from '../../../img/speakers/Miletic.png'
import mileticVideo from '../../../video/miletic.mp4'
import Mezzalira from '../../../img/speakers/Mezzalira.png'
import Fränkel from '../../../img/speakers/Fränkel.png'
import FränkelVideo from '../../../video/frankel.mp4'
import Walid from '../../../img/speakers/Walid.png'
import Softerrific from '../../../img/speakers/Softerrific.png'
import AWS from '../../../img/speakers/AWS.png'
import Hazelcast from '../../../img/speakers/Hazelcast.png'
import Maroc from '../../../img/speakers/Maroc.png'
import Richardson from '../../../img/speakers/Richardson.png'
import Santana from '../../../img/speakers/Santana.png'
import Cyral from '../../../img/speakers/Cyral.png'
import SouJava from '../../../img/speakers/SouJava.png'
import Guida from '../../../img/speakers/Guida.png'
import Bhattacharya from '../../../img/speakers/Bhattacharya.png'
import BhattacharyaVideo from '../../../video/bhattacharya.mp4'
import Microsoft from '../../../img/speakers/Microsoft.png'
import Nagarro from '../../../img/speakers/Nagarro.png'
import Richards from '../../../img/speakers/Richards.png'
import Teles from '../../../img/speakers/Teles.png'
import telesVideo from '../../../video/teles.mp4'
import Parker from '../../../img/speakers/Parker.png'
import Redgate from '../../../img/speakers/Redgate Software.png'
import Choudhuri from '../../../img/speakers/Choudhuri.png'
import Xanadu from '../../../img/speakers/Xanadu.png'
import Karina from '../../../img/speakers/Karina.png'
import Neimer from '../../../img/speakers/Neimer.png'
import neimerVideo from '../../../video/neimer.mp4'
import Kamaruzzaman from '../../../img/speakers/Kamaruzzaman.png'
import Tornhill from '../../../img/speakers/Tornhill.png'
import CodeScene from '../../../img/speakers/CodeScene.png'
import Lysenko from '../../../img/speakers/Lysenko.png'
import Chats from '../../../img/speakers/13Chats.png'
import Oommen from '../../../img/speakers/Oommen.png'
import Parlakisik from '../../../img/speakers/Parlakisik.png'
import NathRichter from '../../../img/speakers/Nath-Richter.png'
import SonicWall from '../../../img/speakers/SonicWall.png'
import FinancialServices from '../../../img/speakers/Financial Services.png'
import RedHat from '../../../img/speakers/Red Hat.png'
import Oracle from '../../../img/speakers/Oracle.png'
import Khan from '../../../img/speakers/Khan.png'
import Ford from '../../../img/speakers/Ford.png'
import Sadiq from '../../../img/speakers/Sadiq.png'
import Gitau from '../../../img/speakers/Gitau.png'
import Bathia from '../../../img/speakers/Bathia.png'
import Jayanandana from '../../../img/speakers/Jayanandana.png'
import WSO2 from '../../../img/speakers/WSO2.png'
import Lopes from '../../../img/speakers/Lopes.png'
import Google from '../../../img/speakers/Google.png'
import Plotka from '../../../img/speakers/Plotka.png'
import Bender from '../../../img/speakers/Bender.png'
import Huang from '../../../img/speakers/Huang.png'
import Ranganath from '../../../img/speakers/Ranganath.png'
import Intel from '../../../img/speakers/Intel Corporation.png'
import Thoughtworks from '../../../img/speakers/Thoughtworks.png'
import DeveloperArchitect from '../../../img/speakers/Developer to Architect.png'
import Para from '../../../img/speakers/Para.png'
import Cognizant from '../../../img/speakers/Cognizant.png'
import Cardona from '../../../img/speakers/Cardona.png'
import Upadhyaya from '../../../img/speakers/Upadhyaya.png'
import Rochwerger from '../../../img/speakers/Rochwerger.png'
import RedHat2 from '../../../img/speakers/RedHat2.png'
import Siddiqui from '../../../img/speakers/Siddiqui.png'
import Microsoft2 from '../../../img/speakers/Microsoft2.png'
import Indrasiri from '../../../img/speakers/Indrasiri.png'
import Vais from '../../../img/speakers/Vais.png'
import Chyron from '../../../img/speakers/Chyron.png'
import Pavlin from '../../../img/speakers/Pavlin.png'
import BlockCzech from '../../../img/speakers/BlockCzech.png'
import Blat from '../../../img/speakers/Blat.png'
import Populate from '../../../img/speakers/Populate.png'
import Lasselsberger from '../../../img/speakers/Lasselsberger.png'
import Lasssim from '../../../img/speakers/Lasssim.png'
import Asthana from '../../../img/speakers/Asthana.png'
import Quiroga from '../../../img/speakers/Quiroga.png'
import PichinchaCorp from '../../../img/speakers/Pichincha Corp.png'
import Morales from '../../../img/speakers/Morales.png'
import MulesoftSalesforce from '../../../img/speakers/Mulesoft Salesforce.png'
import Hakamine from '../../../img/speakers/Hakamine.png'
import Zinovkin from '../../../img/speakers/Zinovkin.png'
import VK from '../../../img/speakers/VK.png'
import Steyer from '../../../img/speakers/Steyer.png'
import ANGULARarchitectsio from '../../../img/speakers/ANGULARarchitectsio.png'
import Kniphoff from '../../../img/speakers/Kniphoff.png'
import Facebook from '../../../img/speakers/Facebook.png'
import Melamed from '../../../img/speakers/Melamed.png'
import Jit from '../../../img/speakers/Jit.png'
import Awad from '../../../img/speakers/Awad.png'
import Mińkowski from '../../../img/speakers/Mińkowski.png'
import Yasir from '../../../img/speakers/Yasir.png'
import ISAAC from '../../../img/speakers/ISAAC Instruments.png'

export default class VideoPresent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        videoPresent: [
          {
              id: 1,
              track: 'Pro',
              photo: Guida,
              name: 'David Guida',
              position: 'Senior Software Engineer',
              ln: 'https://www.linkedin.com/in/davideguida/',
              logo: Microsoft,
              title: 'Event sourcing: a practical approach',
              abstract: `In this talk we'll see what Event Sourcing is and we'll see how it can be combined with Domain Driven Design in a practical scenario. We'll also see different implementations using on-premise and cloud infrastructure`,
              bio: `David is a software engineer with more than 15 years of professional experience. He's working @ Microsoft on some very cool stuff and he's also a former Microsoft MVP on Developer Technologies. When he's not busy coding, blogging, or looking for the next book to read, you can find him in his kitchen baking cakes.`,
          },
          {
              id: 2,
              track: 'Pro',
              photo: Mezzalira,
              name: 'Luca Mezzalira',
              position: 'Principal Solutions Architect',
              ln: 'https://www.linkedin.com/in/lucamezzalira/',
              tw: 'https://twitter.com/lucamezzalira',
              logo: AWS,
              title: 'Micro-Frontends anti-patterns',
              abstract: `In this session you will learn the most common anti-patterns I've seen in the past 7 years implementing and consulting multiple companies in their journey into the micro-frontends architecture.
              Either you are an expert or a newbie, in this talk you will learn how to structure properly your micro-frontends architecture and how to avoid common mistakes meanwhile building micro-frontends`,
              bio: `Luca is a Principal Solutions Architect at AWS, an international speaker and an author. Over the past 18 years, he’s mastered software architectures from frontend to the cloud, providing the right solution for the context.`
          },
          {
              id: 3,
              track: 'Pro',
              video: BhattacharyaVideo,
              photo: Bhattacharya,
              name: 'Kaushik Bhattacharya',
              position: 'Associate Distinguished Engineer',
              ln: 'https://www.linkedin.com/in/kaushik-bhattacharya-6715203b/',
              logo: Nagarro,
              title: 'Event Driven Architecture and its relevance in modern systems',
              abstract: `Although Event driven architecture is not new, there is still lot of confusion on the concepts and application. Participants will gain a solid conceptual understanding, know what problem does it solve, when to use it and when not to use it(ant-patterns). Right from how the pattern emerged, we will dig into the microservices world to see how the concept can used and implemented in the new ""saga"" version. We will also take some actual cases where its been implemented. Participants will be able to propose this architecture for their customers with much more confidence and reasoning.
              What you will learn:
              Technology always comes with lot of hype. This talk takes away the hype and focusses on the concept in a head first way. Attendees will get a solid understanding of what exactly is an event driven architecture and how to implement one in a real life scenario. With this knowledge they stand in a better position to prescribe such an solution to their customers for solving business problems in optimum way. Also, every pattern has its own pros and cons. This talk will ensure attendees have considered all the factors before making a design choice. As they say, a good architect is not the one who delivers the best architecture, but instead the one who considers all the factors and makes an educated choice.`,
              bio: `I am a technical architect with overall IT experience of ~20 years. I have worked in multiple organizations like Nagarro, CTS, Microsoft, Oracle, etc. I love to solve customer problems using technology. I believe that knowledge has no end but one has to have the right attitude to keep learning and sharing.`
          },
          {
              id: 18,
              track: 'Career',
              photo: NathRichter,
              name: 'Melinda Nath-Richter',
              position: 'Senior Principal Consultant',
              ln: 'https://www.linkedin.com/in/melinda-nath-richter-51464097/',
              logo: Oracle,
              title: 'Tick off core concepts on your Security checklist',
              abstract: `Security has been my focus topic now since more than 15 years, and it is my firm belief that any implementation is based upon solid concepts supported by the organization it’s implemented for and irrespective of the infrastructure (on-prem/cloud/hybrid).
              In order to design effective strategies for Security & Compliance there are fundamental building blocks that can ultimately result for example in a Zero Trust architecture.
              I’m emphasizing that although underlying core concepts might be complex it is worth the effort of all involved parties to take time to adopt and individualise building blocks before taking on components and technologies. In the end you should always be able to connect Security concepts to your objectives, for example speaking in terms of confidentiality, integrity and availability.
              
              What attendees will learn:
              - Start with asking basic questions e.g. Who accessed what and when?
              - Get to know building blocks for your Security concept
              - Connect core concepts to an organisation's objectives`,
              bio: `I'm a Security Consultant with focus on Identity & Access Management solutions since 2005
              and joined Oracle through the acquisition of Sun Microsystems.
              
              I'm involved during Pre-Sales, System Engineering and Implementation activities including
              - Project Management
              - System Design and Architecture
              - Design Verification and Validation Test Definition
              - Analytical and Technical Support.
              
              I've gained experience through customer projects, including international engagements, in various industries such as Financial Services, Public Sector, Insurance, Retail, Manufacturing, Telecommunications, Health Care and Transportation.`
          },
          {
              id: 4,
              track: 'Pro',
              photo: Richards,
              name: 'Mark Richards',
              position: 'Founder',
              ln: 'https://www.linkedin.com/in/markrichards3/',
              tw: 'http://twitter.com/markrichardssa',
              website: 'https://www.developertoarchitect.com/mark-richards.html',
              logo: DeveloperArchitect,
              title: 'Correctly Built Distributed Systems',
              abstract: `Building distributed systems is hard. There are many factors beyond the traditional 8 fallacies of distributed computing that must be taken into account to make any distributed system work properly. In this session you will learn about various elements of distributed systems, including things like migration strategies, service granularity, communication protocols, workflow management, and distributed transactions (to name a few). For each element, you'll learn how to analyze the tradeoffs of each to build the right distributed system for your needs. 
              What you will learn: 
              What choices and decisions go into building a distributed system, and what the tradeoffs are of each of those choices.`,
              bio: `Mark Richards is an experienced, hands-on software architect involved in the architecture, design, and implementation of microservices architectures, service-oriented architectures, and distributed systems in a variety of technologies. He has been in the software industry since 1983 and has significant experience and expertise in application, integration, and enterprise architecture. Mark is the founder of DeveloperToArchitect.com, a free website devoted to helping developers in the journey to becoming a software architect. In addition to hands-on consulting, Mark has authored numerous technical books and videos, including his two latest books Fundamentals of Software Architecture and Software Architecture: The Hard Parts that he co-authored with Neal Ford. Mark has spoken at hundreds of conferences and user groups around the world on a variety of enterprise-related technical topics.`
          },
          {
              id: 29,
              track: 'Pro',
              photo: Ford,
              name: 'Neal Ford',
              position: 'Director, Software Architect, and Meme Wrangler',
              website: 'https://nealford.com/',
              logo: Thoughtworks,
              title: 'Software Architecture: The Hard Parts',
              abstract: `“Software architecture” is the stuff you can’t google. Developers become adept at searching for solutions to the technical issues they face–but what about architects? Their solutions are generic; rather, each decisions features significant trade-offs. This keynote, based on the eponymous book, delves into many of the areas in software architecture where difficult decisions lie and discusses techniques for evaluating those trade-offs to reach informed decisions. The job of an architect isn’t to find silver- bullet solutions to problems (they are too rare); instead, architects should hone their ability to perform objective trade-off analysis for novel situations (because all situations in architecture are novel). This keynote unlocks many techniques that architects can use to make better decisions.`,
              bio: `Neal Ford is Director, Software Architect, and Meme Wrangler at ThoughtWorks, a global IT consultancy with a focus on end-to-end software development and delivery. He is also the designer and developer of applications, articles, video/DVD presentations, and author and/or editor of an increasingly large number of books spanning a variety of subjects and technologies, including the most recent Presentation Patterns. His professional focus includes designing and building of large-scale enterprise applications. He is also an internationally acclaimed speaker, speaking at over 300 developer conferences worldwide, delivering more than 2000 presentations.`
          },
      ],
      videoPresentHide: [
        {
            id: 16,
            track: 'Career',
            photo: Lysenko,
            name: 'Anastasiia Lysenko',
            position: 'CTO',
            ln: 'https://www.linkedin.com/in/anastasiia-lysenko-81435283/',
            logo: Chats,
            title: 'GraphQL: change for the best',
            abstract: `I’d like to share with you real-life code and schema examples how one can successfully integrate GraphQL into backend and how to optimize the result. At the moment it’s hardly unlikely to meet a web-developer, who doesn’t know or doesn’t hear a thing about GraphQL. And it’s understandable: more and more developers choose to begin or continue to use it in their projects due to various advantages. Such as single end-point, extendable hierarchy, strict types, flexibility among others. But the integration part is the hardest here. In this topic you will definitely get in detail is an example of a GraphQL integration into a project.
            What attendees will learn: 
            GraphQL resolver example, useful optimization tips, real-life schema examples`,
            bio: `I have been a Software Developer for more than 13 years and Architect as more as six and counting. Now make a living leading a team as CTO of 13Chats.`
        },
        {
            id: 5,
            track: 'Pro',
            video: telesVideo,
            photo: Teles,
            name: 'Francisco Teles',
            position: 'Cloud Solution Architect',
            ln: 'http://www.linkedin.com/in/franciscoteles',
            logo: Microsoft,
            title: 'Bring innovation anywhere with Kubernetes, Hybrid Cloud and Azure Arc',
            abstract: `While the usage of containers continues to rise at an incredible pace, according to IDC, 75% of enterprise data will be created on the edge by 2025. This means that hybrid cloud environments are here to stay and we need to think how we can bring innovation outside the cloud providers' datacenter walls!

            In this session, let's talk about:
            
            - Evolution on hybrid cloud and Kubernetes adoption
            - What are the challenges in an hybrid and multi-cloud environment?
            - How to step up your hybrid infrastructure with Azure Arc?`,
            bio: `My name is Francisco, and in the last 4.5 years I've been working with several customers and partners by helping them transform their business with the Microsoft cloud ☁️

            I'm a solution architect, and a cloud enthusiast with a huge passion for technology.`
        },
        {
            id: 6,
            track: 'Pro',
            // video: FränkelVideo,
            photo: Fränkel,
            name: 'Nicolas Fränkel',
            position: 'Developer Advocate',
            tw: 'https://twitter.com/nicolas_frankel',
            title: 'Evolving your APIs, a step-by-step approach',
            abstract: `When you publish your first HTTP API, you’re more focused on short-term issues than planning for the future. However, chances are you’ll be successful, and you’ll “hit the wall”. How do you evolve your API without breaking the contract with your existing users?
            In this talk, I’ll first show you some tips and tricks to achieve that: moving your endpoints, deprecating them, monitoring who’s using them, and letting users know about the new endpoints. The talk is demo-based, and I’ll use the Apache APISIX project for it.`,
            bio: `Developer Advocate with 15+ years experience consulting for many different customers, in a wide range of contexts (such as telecoms, banking, insurances, large retail and public sector). Usually working on Java/Java EE and Spring technologies, but with focused interests like Rich Internet Applications, Testing, CI/CD and DevOps. Currently working for Hazelcast. Also double as a trainer and triples as a book author.`
        },
        {
            id: 7,
            track: 'Career',
            video: mileticVideo,
            photo: Miletic,
            name: 'Bojan Miletic',
            position: 'CEO',
            ln: 'https://www.linkedin.com/in/boyan-miletic/',
            logo: Softerrific,
            title: 'Infrastructure as a code: When software becomes hardware',
            abstract: `My goal is to introduce you the infrastructure as a code, benefits of this approach to cloud development and how python magic can make all this a painless process.
            - Introduction of Infrastructure as a code
            - Benefits of Infrastructure as a code
            - Best practices (using version control and testing for you infrastructure)
            - Using python to make things even easier to use
          
            What you will learn:
            Better understanding that in modern world software architecture will include hardware into consideration, ways to identify tasks important for you company, how to delegate things to cloud services and embrace the hardware evolution of your product.`,
            bio: `🎯 Hi, I help AI/ML companies and their AI/data scientists turn ML models from the data lab into PoCs, MVPs, or fully functional products that convincingly prove their value to investors and other important decision-makers.
            
            By using #Python and #AWS as superpowers, I help you get real business value from your ML algorithms.
            
            And I happily consult your AI scientists on how to write clean, reusable code in Python and thus save thousands (or even millions) on ML-based software deployment and development.
            
            Basically, I’m a fractional MLOps (ML + DevOps) for your company.`,
        },
        {
            id: 8,
            track: 'Pro',
            photo: Walid,
            name: 'Largou Walid',
            position: 'Solution Architect',
            ln: 'https://www.linkedin.com/in/largouwalid',
            tw: 'https://twitter.com/wlargou',
            logo: Maroc,
            title: 'Micro-Frontends Architecture',
            abstract: `In this session we will cover designing efficient Micro-Frontends Architecture, and will discuss the principles and challenges around this design paradigm.
            The agenda will be as follows: 
            
            * What are Micro-Frontends
            * How do they relate to Domain Driven Design
            * Micro Frontends Principles
                * Composition
                * Routing
                * Communication
            * What are the challenges
            * Use case
            * Lessons Learned`,
            bio: `Solution Architect with leadership experience in designing innovative business solutions for public and private sector clients. 
            Skilled in cloud computing, information management, solution architecture, and application modernization. Demonstrated proficiency in evaluating requirements for business application integration and service activation.`
        },
        {
            id: 9,
            track: 'Pro',
            photo: Richardson,
            name: 'Rob Richardson',
            position: 'Developer Advocate',
            ln: 'https://www.linkedin.com/in/erobrich/',
            tw: 'https://twitter.com/rob_rich',
            github: 'https://github.com/robrich',
            website: 'https://robrich.org/',
            logo: Cyral,
            title: 'Data Warehouse, Data Lake, Data Mesh, Oh My: The History of Data Storage',
            abstract: `Are you looking to modernize your data infrastructure? Or are you confused at what all the terms mean? Let's look at the history of data storage from the humble beginnings of the relational database through the modern cloud-native data mesh paradigm. Data storage has evolved, and you can too.
            What you will learn:
            1. What is a Data Mesh?
            2. Why is it better?
            3. How did we get here?
            4. So should I throw away my Data Warehouse or Data Lake? (spoiler: no)
            5. How do I get started?`,
            bio: `Rob Richardson is a software craftsman building web properties in ASP.NET and Node, React and Vue. He’s a Microsoft MVP, published author, frequent speaker at conferences, user groups, and community events, and a diligent teacher and student of high quality software development. You can find this and other talks on https://robrich.org/presentations and follow him on twitter at @rob_rich.`
        },
        {
            id: 10,
            track: 'Pro',
            two_person: true,
            photo: Santana,
            photo2: Karina,
            name: 'Otavio Santana',
            name2: 'Karina Varela',
            position: 'Distinguished Software Engineer',
            position2: 'Principal Technical Marketing Manager',
            ln: 'https://www.linkedin.com/in/otaviojava',
            ln2: 'https://www.linkedin.com/in/kmacedovarela/',
            tw: 'https://twitter.com/otaviojava',
            website: 'https://otaviojava.com/',
            logo: SouJava,
            logo2: RedHat,
            title: 'What not to do on your next cloud-native architecture',
            abstract: `A dynamic, cloud-ready architecture is undoubtedly an architect's dream. Unfortunately, many of these dreams fail. Let's talk about failures?
            In this talk, we'll discuss common errors in the architectural design of Java services and definitions in architectural modeling at a broader level that will impact the organization for years to come.
            Let's go further: we'll show you an architectural plan that seeks quick deliveries through CI/CD, but which... fails miserably.
            
            We'll close with cloud-native application architecture and lifecycle best practices and tips that will take you a long way from the path of failure.
            What you will learn:
            Cloud-native implementation, cloud flavors, good practices, how to optimize a Java application in the cloud`,
            bio: `Empowering developers worldwide to deliver better software faster, scalable in the Cloud. Otavio is a passionate software engineer focused on Cloud and Java technology. He has experience mainly in persistence polyglot and high-performance applications in finances, social media, and e-commerce.

            Otavio is a member of both Expert Groups and Expert Leader in several JSRs and JCP executive committee. He is working on several Apache and Eclipse Foundation projects such as Apache Tamaya, MicroProfile, Jakarta EE, where he is leading the first specification at Jakarta EE with Jakarta NoSQL. A JUG leader and global speaker at JavaOne and Devoxx conferences.  Otavio has received recognition for his OSS contributions such as the JCP Outstanding Award, Member of the year and innovative JSR, Duke’s Choice Award, and Java Champion Award, to name a few.`
        },
        {
            id: 11,
            track: 'Pro',
            photo: Parker,
            name: 'Ally Parker',
            position: 'Software Architect - Platform Group',
            github: 'https://github.com/allymparker',
            tw: 'https://twitter.com/theallyparker',
            title: 'Experiences using GitOps and policy as code to create an isolated, multi-team Kubernetes environment',
            abstract: `GitOps is flavour de jour for cloud-native deployment, but how well does it work when used For Real? How can a platform team create shared Kubernetes environments for multiple teams, without being vulnerable to all the classic shared environment problems experienced with VMs and container environments: noisy neighbours taking up all the resources, oops-we-over-wrote-other-teams-config etc.

            FluxCD combined with Kyverno for policy enforcement promises to solve this (as well as a host of other update/deployment problems), but what's it REALLY like to use in anger, across multiple environments used by multiple teams, with little or no knowledge of Kubernetes.
            
            In this talk I'll share some experiences of how we've implemented these technologies, some stories of the technologies and some learned lessons.
            
            You will learn:
            •	An overview of GitOps with Flux CD
            •	Implementing Resource Quotas and Limit Ranges to reduce the likely hood of noisy-neighbours
            •	Using Kyverno’s policy enforcement to ensure teams only make changes to their own namespaces.
            •	Details of how we implemented isolated multi-team environments in shared clusters
            •	Learnings from implementing GitOps in production for multiple teams.`,
            bio: `Ally (he/him) is the Platform Software Architect at Redgate Software in the UK, providing a common technical stack and self-service operating model for 12 engineering teams, so they can focus on providing customer value, instead of all the boring stuff.`
        },
        {
            id: 12,
            track: 'Pro',
            photo: Choudhuri,
            name: 'Arnab Choudhuri',
            position: 'Chief Consultant',
            ln: 'https://www.linkedin.com/in/arnabchoudhuri/',
            logo: Xanadu,
            title: 'Data Interchange formats - Past Present and Future',
            abstract: `Data interchange has varied requirements and so different formats to support the same has had different design goals. In this session we look at different formats, try to understand why they were created, how cloud impacted their creation, the situations where they are most useful and as architects what to use when without bias.
            What you will learn:
            Session audience will come to know of various data interchange formats - csv, xml, json, parquet, avro, thrift, protobuf, messagepack, cbor , etc.. and what to use when`,
            bio: `Arnab Choudhuri is the Founder at Brahma Insights- an analytics startup in software + services space and Xanadu Software- where he helps companies adopt best processes and practices in requirement gathering, architecting and developing applications, designing correct business processes and incorporating new age technologies. Prior to Brahma Insights, he founded Uberanalytix- a web analytics tool to provide click stream and voice of customer analytics, GiftSocial- a gifting site which allowed users to send real gifts to social friends.
            He has earlier been a Consultant, Solutions Architect, Project Manager and Applications developer in a wide variety of applications on domains such as ecommerce, media, investment banking (equity and derivative trade), real estate, gaming, etc. at 123SiteSolutions, investmentz.com, First Global and The Statesman Ltd.
            Arnab is an autodidact with a ravenous appetite for knowledge and books and loves to travel. For fun he goes paraglide & scuba dive.`
        },
        {
            id: 13,
            track: 'Career',
            video: neimerVideo,
            photo: Neimer,
            name: 'Slavik Neimer',
            position: 'Principal Software Engineering Manager',
            ln: 'https://www.linkedin.com/in/slavik-neimer/',
            logo: Microsoft,
            title: 'Big Data Analytics and Azure Data Explorer (Kusto)',
            abstract: `What is Big Data? Why does it get more and more popular? How does it change the world? Demo of interactive queries of Big Data using Azure Data Explorer (Kusto).`,
            bio: `A software engineer with more than 15 years of experience in a wide range of technologies, from Linux kernel drivers to Big Data and Cloud Architecture.`
        },
        {
            id: 14,
            track: 'Pro',
            photo: Kamaruzzaman,
            name: 'Md Kamaruzzaman',
            position: 'Lead Solution Architect',
            ln: 'https://www.linkedin.com/in/md-kamaruzzaman-54280b10/',
            website: 'https://medium.com/',
            tw: 'https://twitter.com/KamaruzzMd',
            logo: FinancialServices,
            title: '20 predictions about the software development trends in 2022',
            abstract: `It will cover the trends in various fields of software development for 2022, including Cloud, Security, Big Data, DevOps, Programming languages, Front-end Frameworks, Back-end frameworks, AI, and others.
            What you will learn:
            A highlight of the latest development/trends in the Software Development Industry in a compact way.`,
            bio: `I am working as Lead Solution Architect with 16+ years of experience in various industry. I have expertise in Full-Stack, BigData, Cloud, Microservices, Software Architecture`
        },
        {
            id: 15,
            track: 'Pro',
            photo: Tornhill,
            name: 'Adam Tornhill',
            position: 'CTO',
            github: 'https://github.com/adamtornhill',
            tw: 'https://twitter.com/AdamTornhill',
            logo: CodeScene,
            title: 'Software observability: Visualize Code Quality at Scale',
            abstract: `Code quality issues cost time, money, and missed deadlines. That's why it's vital to know when you can safely move ahead and implement new features as well as when you might have to take a step back and improve what's already there. It's a hard balance that's going to shift over time, and internal code quality lacks visibility. Sure, a traditional static analysis provides some insights, but like all code analysis techniques they lack context: what's important to your organization and what's the impact?

            This talk takes on that challenge by introducing a new set of techniques known as behavioral code analysis. A behavioral code analysis combines novel quality metrics with data on how the engineering organization interacts with the code you are building. It's people + code. This combination lets you prioritize the parts of your system that benefit the most from improvements so that you can balance short- and long-term goals guided by data. All case studies are from real-world codebases such as the Linux Kernel, Android, and React. This new perspective on software development will change how you view code.
            
            What attendees will learn:
            A new perspective on code quality and its business impact based on recent research findings. A set of techniques that let an architect assess a large codebase within minutes. A set of techniques for communicating trade-offs in implementing new features vs refactoring existing code. Learn from the community: case studies from real-world codebases that the audience is likely to be familiar with.`,
            bio: `Adam Tornhill is a programmer who combines degrees in engineering and psychology. Adam is the founder and CTO of CodeScene where he designs code analysis tools. He's also the author of Software Design X-Rays, the best-selling Your Code as a Crime Scene, Lisp for the Web and Patterns in C. Adam's other interests include modern history, retro computing, music and martial arts.`
        },
        {
            id: 17,
            photo: Oommen,
            name: 'Runcy Oommen',
            position: 'Principal Software Engineer',
            ln: 'https://www.linkedin.com/in/runcyoommen/',
            tw: 'https://twitter.com/runcyoommen',
            logo: SonicWall,
            title: 'Designing a platform agnostic High Availability system',
            abstract: `In this session, we would be covering how we can design a high resilient HA solution which could be deployed on any underlying platform. With rise in remote work and overnight digital transformation across verticals, the need for stable systems is in demand more than ever before.
            The sessions would start with the basics aspects of HA, the background and necessity for such a system before diving into the architectureal considerations.
            The design aspect mentioned in the talk would be language agnostic to take the bias away from any language implementation specifics. However, there will be deep down architecture discussions on since this system is meant for cloud, on-prem or hybrid scenarios. Another variance in the setup architecture would be HA cluster design on an open network (with internet) or closed down network (for govt Fed customers)
            The below aspects for design considerations:
            1. Implementation of a channel between two nodes which will transfer critical info b/w them as the heartbeat (HB) mechanism
            2. Failover mechanism implementation for a HB failure - threshold checks, failure buffer etc..
            3. Implementation of critical service monitoring, watchdog implementation and failover mechanism implementation
            4. Implementation of a probe to determine critical network connectivity, threshold checks and failover mecahnism
            5. Implementation of pre-emption functionality
            6. Implementation of a floating IP to determine the "active" node`,
            bio: `An accomplished software engineer with a string of projects primarily in the security domain. Runcy strives constantly to build better software with extra focus on data security that would be kept throughout it’s life-cycle and not just as an after-thought. Considers himself to be a perfectionist yet remains practical, knowing where to draw the line and stop.`
        },
        {
            id: 19,
            track: 'Pro',
            photo: Parlakisik,
            name: 'Murat Parlakisik',
            position: 'Senior software developer',
            ln: 'https://www.linkedin.com/in/parlakisik/',
            title: 'Architecture model changes for mobile development from first mobile phones to 5G era',
            abstract: `We will going through application architecture evolution from first mobile phones to next generation & 5G . What should we aware of new technologies?  What kind of arhictecture should we use? How can we decide?

            What attendees will learn:
            Telecommunication evolution , Edge computing, 5G and project ideas.`,
            bio: `20+ years software development from telecommunication area to cloud.`
        },
        {
            id: 20,
            track: 'Pro',
            photo: Khan,
            name: 'Asif Khan',
            position: 'Cloud Solution Architect',
            ln: 'https://www.linkedin.com/in/asif-khan-34458b6',
            tw: 'https://twitter.com/khanasif1',
            github: 'https://github.com/khanasif1',
            website: 'https://khanasif1.wordpress.com/',
            yb: 'https://www.youtube.com/channel/UC2nMuCbmLuLzlwrPF3kgZ9w',
            logo: Microsoft,
            title: 'Scaling System Integration with Cloud',
            abstract: `System integration is required by every organization be it small/medium or large enterprise. Historically point to point integrations were build to accomplish data share between systems. This was limited with scale, complex to enhance  and hard to manage.
            1. In this talk I will share how I helped large enterprises to small corporates in build integrations platforms which are scalable, extendable and low touch to manage.
            2. I have been in the Integration space for more than 7 years now and overall worked for more than 15 year building applications and integration platforms`,
            bio: `I am a Cloud Solution Architect at Microsoft. I have extensive industrial experience with Microsoft Cloud Technologies, which includes implementing enterprise solutions using power of Azure cloud.
            I'm a Father of 2 great kids, Husband, Developer, Technology Scout and a Educator`
        },
        {
            id: 30,
            track: 'Pro',
            photo: Lopes,
            name: 'Paulo Lopes',
            position: 'Principal Software Engineer',
            tw: 'https://twitter.com/pml0p35',
            logo: RedHat,
            title: 'FIDO2/Webauthn: Replace passwords with strong phishing free security',
            abstract: `The end of password-based authentication is near. Weak passwords are the cause of endless security breaches, and the constant reuse of the same password across different accounts is what keeps the clock ticking for the next breach to happen.
            The FIDO2 standard aims to replace passwords entirely, and there is a good deal of chance that it will succeed. It has gained significant momentum in the past couple of years, as all major browser and operating system vendors fully jumped on board.
            This talk will provide a deep dive of the FIDO2 and W3C WebAuthn standards, with the main focus on how to quickly implement it on any JVM/GraalVM based application using open-source FIDO Alliance conformant libraries.
            Best practices, including security token lifecycle management, will also be covered.
            
            What you will learn:
            After this talk, the audience will be fully aware of the security risk passwords are. How simple tricks can make anyone fall for a phishing attack and the problems of password reuse.
            Attendees will clearly identify why FIDO2/webauthn is a good solution to address all the issues above and how to quickly bootstrap a project with FIDO2/webauthn in.
            Most important, attendees will be able to build secure biometric authentication systems that are stronger than the current state of the art of 2nd factor authentication systems.`,
            bio: `I'm Paulo, and I've used my 15+ years of software development experience to write secure distributed system and open-source libraries for many industries. In the last 7 years, I work as core developer on open source reactive systems libraries that power high-performance systems and frameworks you may use daily. I've implemented one of the first FIDO2 conformant open-source libraries for the JVM and am a true believer and advocate for the use of strong security standards on the JVM.`
        },
        {
            id: 31,
            track: 'Career',
            photo: Gitau,
            name: 'Moses Gitau',
            position: 'Software Engineer',
            ln: 'https://linkedin.com/in/moses-gitau',
            logo: Microsoft,
            title: 'The Functional Programming Paradigm',
            abstract: `Functional programming is a current software programming trend that is very powerful and useful. It is actually based on mathematical functions that use conditional expressions and recursion to perform computation. 
            The importance of functional programming is that it ensures code predictability since pure functions do not support state and have no side-effects. Due to this no-state support, parallel programming also becomes efficient as the functions do not share an external state.
            The support for nested functions also improves code re-usability and readability.
            Some popular functional programming languages include Haskell, Clojure, Python and Lisp, although popular languages such as JavaScript and Java have support for functional programming techniques.
            My experience with functional programming mainly comes from JavaScript and Java where I make use of the programming paradigm to tap into the many advantages of Functional programming.
            
            What you will learn:
            An understanding of what functional programming is.
            Advantages of functional programming
            Contrast between Functional Programming and Object Oriented Programming.
            An example of how Functional Programming can be used in Java.
            Higher Order Functions and Recursion`,
            bio: `Moses Gitau has 4 years of professional experience in the tech industry building and designing software.`
        },
        {
            id: 32,
            track: 'Pro',
            photo: Bathia,
            name: 'Pranjal Bathia',
            position: 'Principal Architect',
            ln: 'https://www.linkedin.com/in/pranjal-bathia-1b771a15/',
            logo: RedHat,
            title: 'Tips for stakeholder management in enterprise architecture',
            abstract: `Enterprise architecture (EA) plays a vital role in solving large and complex problems of the organisation. One of the major success factors in designing EA is effective collaboration between the architects and stakeholders. Lack of ability to manage stakeholders effectively is a larger reason for failure of the EA design compared to technology..
            Enterprise architects have to generally collaborate with a long list of stakeholders from a variety of backgrounds. Fulfilling the requirements from each of those stakeholders would need effective modelling language and a systematic approach throughout the process.
            
            In this talk, I would like to share some tips I learned from my experience being in this domain and handling one of the enterprise-scale initiatives as an Enterprise Architect.
            
            What attendees will learn:
            
            Effective usage of Archimate as a modelling language
            Tips for systematic approach while drafting enterprise architecture`,
            bio: `Pranjal Bathia is a Principal Architect within the PnT Operation and Infrastructure team at Red Hat. She has 12+ years of experience in designing and developing solutions and products for challenging business problems. In her current role, she has architected the Master Data Management solution for Red Hat. Before this, she has developed enterprise middleware solutions that handle millions of events on a daily basis. 

            Pranjal holds a master's degree in Information Security from one of India's premier engineering institutes, NIT Warangal. She is a Mom of two beautiful daughters and is usually busy balancing daily chores and work. In her free time, she likes using her creative skills to recycle waste into artistic pieces, painting, handicrafts, etc.`
        },
        {
            id: 33,
            track: 'Pro',
            photo: Jayanandana,
            name: 'Nilesh Jayanandana',
            position: 'Architect',
            ln: 'http://linkedin.com/in/nilesh93',
            logo: WSO2,
            title: 'Architecting multi tenant Platform on Kubernetes',
            abstract: `Kubernetes is a container orchestration platform that is currently supported by many clouds. Since Kubernetes is still new and fresh, many engineers and architects don’t have much insight on how to properly handle security in a Kubernetes environment. In this talk i will be talking about how to run a multi tenanted platform in Kubernetes with proper security standards taking my own experience into play from the current development platform I am building at my organization.

            What you will learn: Kubernetes Security, DevSecOps`,
            bio: `Nilesh is an experienced architect who has been in the industry for more than 8 years. One of the key achievements in his career was successfully building a devops automation platform which helped multiple enterprises make their operations more efficient and later the success of the product led to the acquisition of Platformer by WSO2. Nilesh is a community lead in Sri Lanka who spends most of his time engaging with the community sharing his knowledge.`
        },
        {
            id: 34,
            track: 'Pro',
            photo: Bender,
            name: 'Adam Bender',
            position: 'Sr. Staff Software Engineer',
            ln: 'https://www.linkedin.com/in/theadambender/',
            logo: Google,
            title: 'Complexity is the Problem',
            abstract: `It's 2021 and software has eaten the world. Now the world has heartburn. Modern software engineering is defined by complexity. It affects almost everything we do, yet despite many advances languages, processes, and techniques, we still find ourselves struggling to reason about the systems we are building. I think we as an industry are in need of a radical shift in our recognition of, and approach to dealing with complexity. In this talk I will define complexity, discuss why it is so hard to address, and point in several directions we may consider to help us restore intellectual control of our systems.

            What you will learn:
            An understanding of the nature and cost of complexity. Motivations to address complexity. Ideas and strategies for how to manage complexity in a given project.`,
            bio: `Adam Bender has been a professional software engineer for more than 15 years. In that time, he has worked on a variety of systems including telephony, electrical grid management, and consumer web applications, all while applying well-known agile principles. For the last 9 years, he has been at Google where he has helped to modernize Google’s call centers and more recently, develop internal frontend infrastructure in support of hundreds of products. He is the Code Health practices lead for Google and has taught over 20,000 Nooglers how Google thinks about software quality. Adam is the author of the Testing Overview chapter in the O'Reilly book: Software Engineering at Google.`
        },
        {
            id: 35,
            track: 'Pro',
            photo: Plotka,
            name: 'Bartlomiej Plotka',
            position: 'Principal Software Engineer | Architect',
            website: 'https://www.bwplotka.dev',
            tw: 'https://twitter.com/bwplotka',
            logo: RedHat,
            title: 'Designing Pragmatic Observability that Works: Avoiding Pitfalls ',
            abstract: `No matter if you run functions in a Serverless world, containers on managed Kubernetes, virtual machines in the cloud or bare-metal servers--you need robust Observability! 

            In this talk you will learn:
            
            * What matters when designing an Observability system.
            * How to avoid buzzwords, enormous bills, disruption and other pitfalls.
            * Trends and solutions.
            
            Explained by Bartek, Architect @ Red Hat in Observability Group, the CNCF TAG Observability Tech Lead, core maintainer of CNCF open-source projects like Prometheus and Thanos and O'Reilly author. Learn what patterns Bartek uses during his work, what works and what does it for Red Hat, our customers and users in the community! 
            
            What you will learn:
            * What are important challenges and typical pitfalls when bringing Observability for your infrastructure and applications.
            * How experts solve those problems.
            * What solutions can help in open source and among providers.`,
            bio: `Bartek Plotka is a Principal Software Engineer at Red Hat with a background in SRE, currently working on Observability. As the co-author of the CNCF Thanos project and core maintainer of various open-source projects including Prometheus, he enjoys building OSS communities and maintainable, reliable distributed systems, ideally in Go. CNCF Ambassador. On top of that, he is active in the CNCF SIG Observability as the technical lead. He writes a book titled "Efficient Go" with O'Reilly.`
        },
        {
            id: 37,
            track: 'Pro',
            photo: Huang,
            name: 'Shuzhi Huang',
            position: 'Engineering Manager',
            logo: Google,
            ln: 'https://www.linkedin.com/in/shuzhi-huang-89aa58a6/',
            title: 'Design a mobile app logging platform that scales for hundreds of engineers',
            abstract: `Logging for a giant app is challenging. Besides the general challenges such as mobile network reliability, data quality or performance concern. For a giant app you have to control the log growth sustainably, ensure data interoperability across logs from teams who probably even don't know each other, avoid the tragedy of the commons, reduce cognitive load for logging, control data quality without slowing people down...
            The talk shares some experience we learnt in designing a sustainable logging platform that is effective and scales. It will cover the principles, origins, evolvement, and some good practices. The talk will steer away from specific technical terms or implementation details, but the abstract design concepts should be transferrable and IMO way more important. I think it's a fun story of a platform team (well described concept from Team Topologies) in action.`,
            bio: `Shuzhi Huang, 
            Engineering Manager, Google
            Shuzhi currently leads teams on logging and experimentation for a Google Android application and dedicates with passion to drive for product velocity improvement.`
        },
        {
            id: 38,
            track: 'Pro',
            photo: Sadiq,
            name: 'Suleiman Sadiq',
            position: 'Senior App Platform Solutions Architect',
            logo: RedHat,
            ln: 'https://www.linkedin.com/in/suleimansadiq',
            title: 'Hybrid-cloud application integration with open-source: Kubernetes, Kafka, Skupper and GitOps',
            abstract: `Are you looking to get started with containers, Kubernetes and hybrid cloud but don’t know where to start? In this session, we will learn about multi-cloud/hybrid cloud integrations using Kubernetes.

            In this session, I will talk about:
            - Containers and Kubernetes
            - How to quickly get started with Kubernetes using OpenShift
            - Managing hybrid Kubernetes deployment
            - Integrating hybrid-cloud applications using Skupper and Kafka
            - How to maintain consistency and state of hybrid Kubernetes environments using OpenShift GitOps
            - Present a hybrid-cloud use-case
            
            You will learn how to design, architect, integrate and manage hybrid cloud applications using open-source`,
            bio: `Senior Solutions Architect focused on helping public sector and healthcare customers modernize their existing applications, build new applications faster and leverage hybrid/multi-cloud architectures using open-source`
        },
        {
            id: 39,
            track: 'Career',
            photo: Para,
            name: 'Jorge Para',
            position: 'Engineering director',
            logo: Cognizant,
            ln: 'https://es.linkedin.com/in/jorgeparamolina',
            title: 'Migrating DevOps for mobile to the cloud',
            abstract: `DevOps for mobile is a complex topic for reasons like the pace of change, the variety of tools or the deep knowledge of mobile-specific technologies that require In-house solutions have been the choice for a long time due to the lack of solid alternatives in the cloud. Luckily, that is changing now.
            In this talk I will:
            - Explore the cloud alternatives to in-house solutions, with their pros and cons
            - Provide a decision tree for choosing the best alternative
            - Share best practices for migration
            Gain a deep understanding of the cloud technologies for DevOps for mobile. Learn how to decide in different scenarios. Learn best practices for a successful migration from in-house to cloud.`,
            bio: `I am an engineering director with a strong background in programming and software architecture design. I am an advocate for agile development, automated testing, code quality and continuous improvement.
            I enjoy programming and always find time to improve my technical skills and learn new programming languages. Lastly diving deep into machine learning, VR and AR.`
        },
        {
            id: 40,
            track: 'Pro',
            photo: Cardona,
            name: 'Rafael Cardona',
            position: 'Cloud Solution Architect',
            logo: RedHat,
            ln: 'https://www.linkedin.com/in/rcardona-cloud/',
            title: 'Open Data Hub, A data and AI Platform for Kubernetes.',
            abstract: `I will talk about Artificial Intelligence and Machine learning on OpenShift with Open Data Hub. It started internally in Red Hat as a platform on Openshift for data scientists to store data and run their data analysis workload on, hence the word Data Hub. However, the platform grew and we added multiple tools and components to get to an end-to-end AI/ML platform. Open Data Hub is not a single application, it is a platform with multiple tools running on OpenShift.

            In Red Hat, we have gained formidable operating expertise on how to run AI/ML in production. To run this type of platform is very complex, and requires a comprehensive way to orchestrate many applications (ML stack) on a containerized infrastructure.
            
            As our Data Scientist started using Open Data Hub internally we started building the Open Data Hub project based on their requests and challenges they faced. We realized some of these challenges are faced by many of our customers with their own AI/ML teams. For AI/ML projects there is always a team of Data Scientists, Data Engineers, DevOps, Product Owners, and Business Developers that need to collaborate and work together. Sharing and collaborating around AI/ML development is difficult, mostly manual, and is error-prone. Another important challenge is computer resources, AI/ML workloads are compute-heavy, and CPU, memory, and storage are not unlimited resources on clusters in any development or production environments. A final challenge, one that is very critical, is delivering to production and the production/development lifecycle. 
            
            I will talk about how it works, and how we (Red Hat) have addressed these challenges in production.        
            Kubernetes, Python programming, cloud, artificial intelligence`,
            bio: `I am a Cloud Solution Architect specialized in Red Hat Openshift. I am based in Amsterdam, but my operation region is EMEA. I enjoy my work very much, and that is in a nutshell; enabling customers and partners to modernize and optimize the IT infrastructure on the private, public or hybrid cloud. The type of technology that I focus is enterprise kubernetes, and more specifically High Performance computing systems, and more recently AI/ML stack on containerized platforms.`
        },
        {
            id: 41,
            track: 'Pro',
            photo: Upadhyaya,
            name: 'Goutham Upadhyaya',
            position: 'Global Cloud Solution Architect',
            logo: Microsoft,
            ln: 'https://www.linkedin.com/in/goupadhy/',
            title: 'DAPR- Distribution Application Runtime',
            abstract: `What is DAPR? 
            How can Dapr be used for building reliable and portable microservices?
            You will learn in this session how to use Dapr in microservice communication and pub-sub, and how best practices for security and portability are already built-in. Will also show you tips and tricks to get more gains out of the APIs in your existing code.         
            You will learn:
            
            Build connected distributed applications faster using DAPR
            Leverage DAPR's APIs for building portable and reliable microservices, leverage industry best practices and focus on your application’s logic. Learn more about DAPR's APIs for solving distributed application challenges`,
            bio: `Passionate Technologist, Solution Architect, Developer, Speaker & Problem Solver`
        },
        {
            id: 42,
            track: 'Pro',
            photo: Rochwerger,
            name: 'Benny Rochwerger',
            position: 'Principal Software Engineer',
            logo: RedHat2,
            ln: 'https://www.linkedin.com/in/benny-rochwerger-0aa9073/',
            title: 'Operators: Automating  the lifecycle of your stateful application the Kubernets Way',
            abstract: `Kubernetes chines for stateless applications, unfortunately, to fully leverage Kubernetes capabilities for stateful applications is hard. Kubernetes Operators capture application-specific operational knowledge into Kubernetes native constructs, such that the automation of common Day 1 (installation, configuration, etc) and Day 2 (reconfiguration, updates, backup, recovery, HA, etc) act ivies can be integrated natively into Kubernetes.         

            In this talk, you will learn about what can be achieved with Operators, available Operators, and how to get started to create your own.`,
            bio: `Benny Rochwerger, currently a Principal Software Engineer in Red Hat’s Telco Engagement team, where he works with Red Hat’s partners helping them bring their workloads to Red Hat’s OpenShift Container Platform.  Prior to joining Red Hat, Benny was a senior architect at Radware's CTO office, where he led the group on cloud-native security solutions for 5G networks; and before that Benny was a Senior Technical Staff Member at the IBM Haifa Research Lab, where he led research projects in the areas of network virtualization and cloud computing. His interests include software defined networking, networking virtualization, cloud computing, distributed systems and cybersecurity. Benny holds an MS in Computer Science from the University of Massachusetts Amherst and a B.Sc. in Computer Engineering from the Israel Institute of Technology (Technion)`
        },
        {
            id: 43,
            track: 'Career',
            photo: Siddiqui,
            name: 'Nabil Siddiqui',
            position: 'Cloud Solution Architect',
            logo: Microsoft2,
            ln: 'https://www.linkedin.com/in/nabilshams/',
            title: 'Microservices anti-patterns and pitfalls',
            abstract: `Microservices is a popular topic in software development these days. Many businesses desired to change current monolithic or create new apps based on microservices architectures. The fundamental issue is that while developing microservices architecture, many things that are valuable in monolith are deemed anti-patterns and pitfalls in microservices.        

            The audience will learn about different anti-patterns and pitfalls while implementing microservices architecture and their potential solutions. The talk will provide you with a perspective on evaluating your microservices architecture and how it can be improved by applying microservices design principles and avoiding common pitfalls.`,
            bio: `Nabil Siddiqui, is an author,  open-source contributor and technology speaker who is currently working as a Cloud Solution Architect at Microsoft, focusing on application innovation. He is well versed as a software engineer, consultant, trainer and architect. He has around 20 years of experience, ranging from desktop applications to highly distributed applications on different platforms using various programming languages. During his career, he's accomplished various technical challenges and worked on a broad range of projects that have given him rich experiences in designing innovative solutions to solve difficult problems.`
        },
        {
            id: 44,
            track: 'Pro',
            photo: Indrasiri,
            name: 'Kasun Indrasiri',
            position: 'Senior Product Manager',
            logo: Microsoft,
            ln: 'https://www.linkedin.com/in/kasun-indrasiri/',
            title: 'Cloud Native Event Streaming Architecture',
            abstract: `Today businesses heavily rely on data that is in motion. Most business use cases operate on ‘event streams’ which are a sequence of events or data elements that are made available over time. Event Streaming Platforms allow us to ingest, store, enrich and analyze millions of events in such event streams. 

            Cloud-Native Architecture is all about building software applications as a collection of independent, loosely coupled, business capability-oriented services(microservices), that can run on dynamic environments (public, private, hybrid, multi-cloud) in an automated, scalable, resilient, manageable and observable way. As event streaming is a key requirement in most business use cases, you need to make sure that you use a cloud-native event streaming platform to power your applications. 
            
            In this session, we dive into some of the key characteristics of cloud-native event streaming architecture which include. 
            * Dynamic scaling - Scale event stream ingestion capacity dynamically 
            * High-Performance data streaming - Low end to end latency, High throughput 
            * Multi-tenanted PaaS with workload isolation - Ability to operate as a multi-tenanted PaaS or dedicated single-tenant service, Minimal cross-tenant interference. 
            * Multi-protocol - Ability to ingest and consume event streams with a wide array of protocols such as AMQP, Kafka, HTTP, WebSockets, and so on. 
            * Elasticity - Elastic streams, Dynamic partition scaling 
            * High availability and resiliency - Replication, Availability Zones 
            * Geo Disaster recovery with data and state replication
            * Security 
            * Stream and Resource governance - Using schema-driven formats, Fine-grained resource governance. 
                    Understand the basics of cloud-native architecture for Event Streaming Platforms, use vendor-neutral canonical dataflow for building event streaming applications, the real world use case of using event streaming with could native apps using Azure Event Hubs, and Stream Analytics.`,
            bio: `Kasun Indrasiri is an author, a product manager, and a software architect with extensive experience in microservices, cloud native architecture and building messaging and integration platforms. 

            He is the author of the Design Patterns for Cloud Native Applications (O'Reilly, 2021), gRPC: Up and Running (O'Reilly, 2020) and Microservices for Enterprise (Apress, 2018).  He was a speaker at several conferences including, KubeCon + CloudNativeCon North America 2020, O’Reilly Software Architecture Conference and GOTO Con. 
            
            Kasun is a senior program manager at Microsoft Azure Messaging Services.`
        },
        {
            id: 45,
            track: 'Pro',
            photo: Vais,
            name: 'Zdenek Vais',
            position: 'Principem Software Architect',
            logo: Chyron,
            ln: 'https://www.linkedin.com/in/zdenekvais/',
            title: 'Architectural thinking aka how to turn independent desktop apps into web-based system for live broadcasting',
            abstract: `9 months ago we were standing in front of a difficult task: Our company had multiple independent purely designed applications and our goal was to turn them into a real-time web-based system combining their functionality.

            By applying basic architectural principles and patterns we were able to build a well-designed system of services with web UI focused on performance and maintainability.
            
            In this talk you will be explained why and how we used the following principles:
             - Application architecture for multiple UIs
             - Domain-centric architecture and DDD
             - In-process event messaging
             - Gradual refactoring
             - Thinking about service dependencies
            
            You will learn:
            
                    Basic architectural thinking, thinking about dependencies and layers. Why and when to use domain-centric and DDD architecture. Why and when to use event messaging. How to think when making technology choices.`,
            bio: `Started working in SW development 13 years ago. Until now I have worked as a developer, team leader, engineering lead, architect and lead architect. Two years ago I have also founded a company providing courses and trainings for both public and companies.`
        },
        {
            id: 46,
            track: 'Career',
            photo: Pavlin,
            name: 'Vaclav Pavlin',
            position: 'Chief Blockchain Officer',
            logo: BlockCzech,
            ln: 'https://www.linkedin.com/in/vpavlin/',
            title: 'How we get chained',
            abstract: `Blockchain brings tons of questions - why, how, with whom, how much, why, why, why? The answers are always long, complicated, subjective, and often not helpful. I would like to dig deeper into why I switched from "traditional" space to blockchain and where do I see the main differences. I hope this will shed some light on why you or your organization might want to consider blockchain in your next app.        

            Personal opinions on differences between the traditional world and world we are probably heading toward where blockchain technologies take much more front seat than today. Give some hints on how people may transition to blockchain and web3 oriented development. Get a better understanding of the pitfalls of current blockchains and where the "traditional" world might keep winning.`,
            bio: `Ex-Red Hat architect focused on contrainerization and distributed architecture on top of bleeding edge orchestration platforms turned into blockchain enthusiast.`
        },
        {
            id: 47,
            track: 'Career',
            photo: Blat,
            name: 'Fernando Blat',
            position: 'CTO',
            logo: Populate,
            tw: 'https://twitter.com/ferblape',
            title: 'The data lifecycle in small teams: from ETLs to Dataviz',
            abstract: `Data has become so important in the last few years, and more products and tools are becoming necessary to understand it.

            In this talk I want to focus on our experience dealing with the mess of processing public and private small and medium datasets, for building tech products that help people to digest this information: from data processing with ETLs and data pipes to exploration tools, such as data visualizations developed in D3.js.        
            
            You will learn:
            
            How to integrate data processing tools and workflows in small teams. 
            Which are the best tools to visualize this data. 
            How people want to consume it.`,
            bio: `Fullstack developer for the last 15 years, Fernando is a passionate about development, data and data visualization. A few years ago he co-founded Populate, a small studio to develop projects around civic tech and open data, with customers such as the ICIJ (Panama Papers), AidData or different newsrooms and local administrations.`
        },
        {
            id: 48,
            track: 'Pro',
            photo: Lasselsberger,
            name: 'Simon Lasselsberger',
            position: 'Software Architectg',
            logo: Lasssim,
            ln: 'https://www.linkedin.com/in/lasssim/',
            title: 'Communicate efficiently with Software Architecture Diagrams',
            abstract: `The ultimate goal of any software is to offer a great experience to its users. User interfaces and workflows must be clear, self-explanatory, and appealing. 

            Software documentation should have a similar goal. Comprehensive diagrams that visualize ideas and concepts are mandatory to do that. 
            
            I am going to show how to create beautiful diagrams with the right level of detail, to communicate efficiently with other software professionals and stakeholders.        
            Attendees will learn why visual communication is important and how diagrams with the right level of detail can be created quickly with free tools.`,
            bio: `Simon helps software companies design scalable software systems. With his consulting company Lasssim he supports developers and decision-makers with solving architectural challenges, mentoring, and coaching. He started his professional life in chip design, but he’s always loved software more. Not just because software is way more agile than hardware development, but because tackling bigger problems at higher levels of abstraction is more appealing to him. This also explains his love for Software Architecture and Microservices.

            He likes working with Startups especially. His most noticeable success was when he worked with Runtastic and was responsible for their system architecture. They got acquired by adidas in 2015 for €220 million. Since then, he helped numerous other Startups to move fast with software architecture.`
        },
        {
            id: 49,
            track: 'Pro',
            photo: Asthana,
            name: 'Saurabh Asthana',
            position: 'Senior Solutions Architect',
            logo: RedHat,
            ln: 'https://www.linkedin.com/in/saurabh-asthana-597ab073/',
            title: 'Edge Computing and Architectures',
            abstract: `Edge or Fog computing refers to the concept of bringing computing services closer to service consumers or data sources. This counter-trend to the centralized cloud services is fuelled by emerging use cases like IoT, AR/VR, robotics, machine learning, and telco network functions that require service provisioning closer to users. Edge Computing helps solve the key challenges of bandwidth, latency, resiliency, and data sovereignty. It complements the hybrid computing model where centralized computing is used for compute-intensive workloads and Edge Computing helps solve problems at the source, in near real-time.         

            After the session (40 min) the participants will be able to understand this emerging technology, why is it needed now and in the future, what are the challenges in implementing it, what are the possible solutions and architectures, etc.`,
            bio: `Telco Senior Solutions Architect at Red Hat in the Netherlands. Have been working with various disciplines in Telco for over 10 years. Open Source enthusiast, helping the customers in their Hybrid Cloud Journey.`
        },
        {
            id: 50,
            track: 'Career',
            photo: Quiroga,
            name: 'Carlos Arturo Quiroga Quiroga',
            position: 'Digital Architecture Corporate Leader',
            logo: PichinchaCorp,
            ln: 'https://www.linkedin.com/in/carlosarturoq/',
            title: 'Why does Open Banking need a microservices architecture approach?',
            abstract: `I will explain why does a strategy of opening banking, need a well developed and deployed microservices architecture approach. You will learn more about the financial platforms trends and the evolution of the banking industry`,
            bio: `Corporate Digital Architecture Leader, Work as Solution Architect at different companies; now Financial Latam Group, in past IBM Global Business Services, Oracle & EDS (HPe now DXC) in Colombia. Agile Coach, Mentor & Design Thinking practitioner.

            Has experience in developing & deploying distributed solutions, including APIs strategy, API Economy adoption (ecosystems & platform), Cloud, Omnichannel, Microservices, SOA (Service Oriented Architecture) & Front End technologies (Ionic, Bootstrap, Angular, etc.), applying Agile & Design Thinking techniques to align IT solutions to customer needs.`
        },
        {
            id: 51,
            track: 'Pro',
            photo: Morales,
            name: 'Andrés Céspedes Morales',
            position: 'Master Instructor',
            logo: MulesoftSalesforce,
            ln: 'https://www.linkedin.com/in/andrespedes12/',
            github: 'https://github.com/pedes',
            title: 'Why do you need to become a Security Architect?',
            abstract: `In a world where security threats are a constant, developers and architects need to step up and change the way of approaching software and the development life cycle. Security often is seen as a responsibility of a dedicated team solely, but the reality is different and security is involved from the project conception until its maintenance, that's why everyone needs to get started in the field of cyber security.         Change the perspective of software development in terms of security.

            Learn about processes, tools, and resources to get started in the field of software architecture and cyber security.`,
            bio: `Java Advocate and Software Architect working with the development community since 2005, dedicated to the continuous improvement in areas like security and architecture. Currently teaching software architecture at Mulesoft/Salesforce`
        },
        {
            id: 52,
            track: 'Career',
            photo: Hakamine,
            name: 'Vinicius Hakamine',
            position: 'Innovation Solution Engineer',
            logo: Oracle,
            ln: 'https://www.linkedin.com/in/viniciushakamine/',
            title: 'Levered Customer Experience with NLP/AI',
            abstract: `Show solution-based and AI services to monitor brands on social media (Facebook, Twitter, etc). The solution is NLP-based with sentiment analysis, which possibles bring customer perception for them.
            Working with AI services is an awesome approach to accelerate development and bring innovation, avoiding a lot of requirements like data scientists skills, statistics knowledge, etc. 
            Last black Friday, we developed a solution aimed to help the retail industry to monitor their brands during the event or for long-time adoption. Bring innovation for them and the first experience with AI Services.        
            
            You will learn more about AI services and understand the concepts features that are available to develop using AI services without requiring effort and advanced knowledge in statistics`,
        },
        {
            id: 53,
            track: 'Career',
            photo: Zinovkin,
            name: 'Pavel Zinovkin',
            position: 'Chief Information Officer',
            logo: VK,
            ln: 'https://www.linkedin.com/in/pzinovkin/',
            title: 'Software architecture in a non-enterprise world - a practical approach',
            abstract: `There is a saying: no plan survives contact with the enemy. The same is correct for software architecture, especially in startups and fast-paced tech companies.
            In this talk, I will share my takeaways and observations on different approaches to architecting software I've observed over the years. And is it even worth trying to invest in architecting to perfection?        
            
            You will have a better understanding of the state of software architecture in fast-paced environments.`,
            bio: `Pavel is a software engineer, CTO, engineering manager with over 16 years of professional experience. Over the years, he helped build products used by millions of users at startups and big technology companies.`
        },
        {
            id: 54,
            track: 'Pro',
            photo: Steyer,
            name: 'Manfred Steyer',
            position: 'Trainer, Consultant, Programming Architect',
            logo: ANGULARarchitectsio,
            tw: 'https://twitter.com/ManfredSteyer',
            title: 'Beyond Micro Frontends: Frontend-Moduliths with Nx',
            abstract: `Micro frontends are not the only solution, nor are they suitable in all scenarios. In this session, I will present an alternative architecture approach that we have used successfully in numerous projects over the last few years: the Frontend Modulith.

            We discuss dividing your application into manageable parts, mapping your business domains, categorizing libraries, and then defining access restrictions to enforce your intended front-end architecture. You'll also see how you can drastically improve the performance of your CI process with incremental builds and tests as well as with local and distributed build caches. The examples use Nx, a tool created by former Google employees to help build structured enterprise applications using Angular, React, Vue, and other frameworks.
            
            In the end, you will know if frontend moduliths are the right approach for you and how to build your Angular applications with them.        
            
            The attendees will ...
            
            ... know and be able to evaluate the disadvantages of micro frontends
            ... know and be able to evaluate possibilities for frontend moduliths (modular monoliths).
            ... be able to categorize application parts
            ... know concepts for enforcing a frontend architecture`,
            bio: `Trainer, consultant and programming architect with focus on Angular for enterprise-scale solutions. Google Developer Expert (GDE) and Trusted Collaborator in the Angular team who writes for O'Reilly, the German Java Magazine, and windows.developer. Regularly speaks at conferences.`
        },
        {
            id: 55,
            track: 'Career',
            photo: Kniphoff,
            name: 'Ana Kniphoff',
            position: 'Solutions Architect',
            logo: Facebook,
            ln: 'http://linkedin.com/in/anamariakniphoff/',
            title: 'How to scale the Solutions Architect influence cross-functionally',
            abstract: `Since the Solutions Architect profile is a hard one to find, we must shape our influence as Technical Advisors to properly scale across different functional areas, so we can become key to unlock the scalability of our business without losing technical excellence in the way. So, at this talk, we are gonna discuss best practices and highlight the importance of measuring success and influence over scalable scenarios.        

            You will learn more about:
            
            Metric-oriented thinking, scalability of technical and advisory influence, business scalability.`,
            bio: `Solutions Architect at Meta with almost 10 years of experience with Software Development, Infrastructure Administration and Automation, Technical Support, Infrastructure and Engineering Leadership, Pre Sales and Technical Account Management.`
        },
        {
            id: 56,
            track: 'Pro',
            photo: Melamed,
            name: 'David Melamed',
            position: 'CTO',
            logo: Jit,
            ln: 'https://www.linkedin.com/in/mlmd/',
            title: 'Forget Mono vs. Multi-Repo - Building Centralized Git Workflows with Python',
            abstract: `The mono vs. multi-repo is an age-old debate in the DevOpsphere and one that can still cause flame wars. What if I were to tell you that you don't have to choose?
            In this talk, we will dive into how we built a centralized Git workflow that can work with any kind of repo architecture, delivered with Python.
            
            One of the greatest recurring pains in CI/CD is the need to reinvent the wheel and define your CI workflow for every repository or (micro)service when eventually 99% of the config is the same. What if we could hard reset this paradigm and create a single, unified workflow that is shared by all of our reports and microservices? 
            
            In this talk, we will showcase how a simple solution implemented in Python, demoed on Github as the SCM, and Github Actions for our CI enabled us to unify this process for all of our services and improve our CI/CD velocity by orders of magnitude.        How to build a centralized CI workflow`,
            bio: `Currently CTO and Co-Founder of Jit, the Continuous Security platform for Developers. David has a PhD in Bioinformatics and for the past 20 years has been a full-stack developer, CTO & technical evangelist, mostly in the cloud, and specifically in cloud security, working for leading organizations such as MyHeritage, CloudLock (acquired by Cisco) and leading the 'advanced development team' for the CTO of Cisco's cloud security (a $500M ARR BU).`
        },
        {
            id: 57,
            track: 'Pro',
            photo: Awad,
            name: 'Tamer Awad',
            position: 'Solution Architect',
            ln: 'https://www.linkedin.com/in/tamer-awad-53409118/',
            title: 'Cloud, Fog and Edge: All in one Architectures',
            abstract: `The aim of this research is to extend cloud technology with fog and edge technologies and merge them in one architecture that could fit in multiple industries and applications. The new architecture can touch base with IOT applications and AI applications. It can also touch base with huge distributed applications which use multicloud platforms.`,
            bio: `I have 12+ years of experience. Solution architect working in IBM for 7 years. A master student in computer science. I love software architecture and coding in various programming languages including Java, Python, JavaScript and ESQL. I have worked as software engineer and integration engineer for many years now and I enjoy it very much. I love watching football (soccer) and playing Ping Pong. I am a father to 3 children and they are my running fuel and joy in life.`
        },
        {
            id: 58,
            track: 'Pro',
            photo: Mińkowski,
            name: 'Piotr Mińkowski',
            position: 'Solution Architect',
            tw: 'https://twitter.com/piotr_minkowski',
            title: 'Serverless Java on Knative with Quarkus and Kafka',
            abstract: `In this talk we will focus on integration between Knative Eventing and Apache Kafka, and see how to run Kafka on Kubernetes. How Quarkus framework may simplify and speed up our development on Kubernetes? After a short introduction, we will focus especially on serverless features offered by Quarkus Funqy and integration with Kafka through the SmallRye Reactive Messaging extension. In the live coding session part, we will implement a simple SAGA pattern between three applications written in Quarkus and run it on Knative.`,
            bio: `Piotr currently works as a Solution Architect at Red Hat. He has several years of experience as a Java Developer and Software Architect. He is an enthusiast of new technologies around Java, microservices, containers, and Kubernetes. Blogger at https://piotrminkowski.com. Author of book "Mastering Spring Cloud".`
        },
        {
            id: 59,
            track: 'Career',
            photo: Yasir,
            name: 'Rahat Yasir',
            position: 'Director of Data Science & AI',
            logo: ISAAC,
            ln: 'https://www.linkedin.com/in/rahat-yasir/',
            title: 'Designing Data  & AI Platform in Azure Ecosystem ',
            abstract: `In this session we will talk about data & AI life cycle, components you need to have in your data & AI platform to get maximum ROI, Data & AI platform components in Azure ecosystem, roles to hire to complete your data & AI team and steps you can take to get the most from your data & AI investment.`,
            bio: `Rahat Yasir is selected as Canada's top 30 software developer under 30 in 2018. He is seven times Microsoft Most Valuable Professional Award holder in Artificial Intelligence category. He has years of experience in imaging and data analysis application development, cross platform technologies and enterprise system designing. He is the author of the first eBook on Windows Phone 8.1 app development "Windows Phone 8.1 Complete Solution" and "Universal Windows Platform - Complete Solution". He worked at the University of Saskatchewan - Plant phenotyping and imaging research centre (P2IRC) as a data science and imaging researcher. He was the lead AI developer at Immersive Design Studios and developed world's first AI video upscalling tool. He worked as AI Engineer at Intact Financial Corp and designed production graded AI applications that are serving millions of requests everyday. He worked as the Lead AI Developer at OSEDEA to design the next generation AI solutions for manufacturing industry. He has worked at CAE to design large scale Data & AI Analytics platform and projects for Aviation, Aerospace, Defense & Healthcare systems. Currently he is working at ISAAC Instruments as Director of Data Science & AI to lead their Data & AI department for data driven & AI powered transportation industry.`
        },
      ],
      hide: true,
    }

    this.showSpeakers = this.showSpeakers.bind(this);
  }

  showSpeakers() {
    this.setState({hide: !this.state.hide});
  }

  renderVideoPresent(item, i) {
    return (
      <>
      {item.two_person === undefined && <div className="videoPresent_item">
         <div className='videoPresent_item-info_wrap'>
         {item.track && <div className="speakers_track">{item.track}</div>}
            <div className='videoPresent_item-video'>
            {item.video && <ReactPlayer
              url={item.video}
              width="100%"
              height="100%"
              playing
              controls
              playIcon={<button className='videoPresent_item-play'></button>}
              light={item.photo}
            />}
            {!item.video && 
              <img src={item.photo} alt="" />
            }
            </div>
            <div className='videoPresent_item-content'>
                <p className="videoPresent_item_name">{item.name}</p>
                <p className="videoPresent_item_position">{item.position}</p>
                <div className='videoPresent_social_link'>
                    {item.tw && <a className='social_link social_tw' target='_blank' rel="noreferrer" href={item.tw}></a>}
                    {item.github && <a className='social_link social_github' target='_blank' rel="noreferrer" href={item.github}></a>}
                    {item.ln && <a className='social_link social_ln' target='_blank' rel="noreferrer" href={item.ln}></a>}
                    {item.fb && <a className='social_link social_fb' target='_blank' rel="noreferrer" href={item.fb}></a>}
                    {item.website && <a className='social_link social_website' target='_blank' rel="noreferrer" href={item.website}></a>}
                    {item.inst && <a className='social_link social_inst' target='_blank' rel="noreferrer" href={item.inst}></a>}
                    {item.yb && <a className='social_link social_yb' target='_blank' rel="noreferrer" href={item.yb}></a>}
                </div>
                <img className='videoPresent_company_logo' src={item.logo} alt="" />
                <p className='videoPresent_title'>"{item.title}"</p>
                <Popup abstract={item.abstract} title={item.title} bio={item.bio} key={i} />
            </div>
        </div>
      </div>}
      {item.two_person === true && <div className="videoPresent_item">
         <div className='videoPresent_item-info_wrap'>
            <div className="speakers_track">{item.track}</div>
           <div className='videoPresent_video videoPresent_video_2'>
              <div className='videoPresent_item-video videoPresent_item-video2'>
              {item.video && <ReactPlayer
                url={item.video}
                width="100%"
                height="100%"
                playing
                controls
                playIcon={<button className='videoPresent_item-play'></button>}
                light={item.photo}
              />}
              {!item.video && 
                <img src={item.photo} alt="" />
              }
              </div>
              <div className='videoPresent_item-video videoPresent_item-video2'>
              {item.video2 && <ReactPlayer
                url={item.video2}
                width="100%"
                height="100%"
                playing
                controls
                playIcon={<button className='videoPresent_item-play'></button>}
                light={item.photo2}
              />}
              {!item.video2 && 
                <img src={item.photo2} alt="" />
              }
              </div>
            </div>
            <div className='videoPresent_item-content'>
              <div className='video_info_block'>
                <div className='video_info_item video_info_item1'>
                  <p className="videoPresent_item_name videoPresent_item_name2">{item.name}</p>
                  <p className="videoPresent_item_position videoPresent_item_position2">{item.position}</p>
                  <div className='videoPresent_social_link'>
                      {item.tw && <a className='social_link social_tw' target='_blank' rel="noreferrer" href={item.tw}></a>}
                      {item.github && <a className='social_link social_github' target='_blank' rel="noreferrer" href={item.github}></a>}
                      {item.ln && <a className='social_link social_ln' target='_blank' rel="noreferrer" href={item.ln}></a>}
                      {item.ln2 && <a className='social_link social_ln' target='_blank' rel="noreferrer" href={item.ln2 }></a>}
                      {item.fb && <a className='social_link social_fb' target='_blank' rel="noreferrer" href={item.fb}></a>}
                      {item.website && <a className='social_link social_website' target='_blank' rel="noreferrer" href={item.website}></a>}
                      {item.inst && <a className='social_link social_inst' target='_blank' rel="noreferrer" href={item.inst}></a>}
                  </div>
                  <img className='videoPresent_company_logo videoPresent_company_logo2' src={item.logo} alt="" />
                </div>
                <div className='video_popup_block'>
                  <p className='videoPresent_title'>"{item.title}"</p>
                  <Popup abstract={item.abstract} title={item.title} bio={item.bio} key={i} />
                </div>
                <div className='video_info_item video_info_item2'>
                  <p className="videoPresent_item_name videoPresent_item_name2">{item.name2}</p>
                  <p className="videoPresent_item_position videoPresent_item_position2">{item.position2}</p>
                  <div className='videoPresent_social_link'>
                      {item.tw2 && <a className='social_link social_tw' target='_blank' rel="noreferrer" href={item.tw2}></a>}
                      {item.github2 && <a className='social_link social_github' target='_blank' rel="noreferrer" href={item.github2}></a>}
                      {item.ln2 && <a className='social_link social_ln' target='_blank' rel="noreferrer" href={item.ln2}></a>}
                      {item.fb2 && <a className='social_link social_fb' target='_blank' rel="noreferrer" href={item.fb2}></a>}
                      {item.website2 && <a className='social_link social_website' target='_blank' rel="noreferrer" href={item.website2}></a>}
                      {item.inst2 && <a className='social_link social_inst' target='_blank' rel="noreferrer" href={item.inst2}></a>}
                  </div>
                  <img className='videoPresent_company_logo videoPresent_company_logo2' src={item.logo2} alt="" />
                </div>
              </div>
            </div>
        </div>
      </div>}
      </>
    )
  }

  render() {
      return (
          <div className="videoPresent">
              <div className='container VideoPresent_wrap'>
                  <div className="video_block">
                    {this.state.videoPresent.map((item, i) => <React.Fragment key={`videoPresent_item-${i}`}>{this.renderVideoPresent(item, i)}</React.Fragment>)}
                    {!this.state.hide && this.state.videoPresentHide.map((item, i) => <React.Fragment  key={`videoPresent_item-hide-${i}`}>{this.renderVideoPresent(item, i)}</React.Fragment>)}
                  </div>
                    <button className='video_btn' onClick={this.showSpeakers}>{this.state.hide ? 'Show more speakers' : 'Hide speakers'}</button>
              </div> 
          </div>
      )
  }
}