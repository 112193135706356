import './CountDown.css'
import React from 'react'; 
import CountDown from "./CountDown/CountDown.jsx";
import moment from 'moment';


export default class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCountdown: true,
            deadLine: moment.utc('2022-03-01T12:00:00+02:00'),
        }

        this.showCountdown = this.showCountdown.bind(this);
    }
    
    showCountdown() {
        this.setState({
            isCountdown: !this.state.isCountdown
        });
    }

    render () {
        return (
            <React.Fragment>
                {moment().isBefore(this.state.deadLine) &&  <div>
                    {this.state.isCountdown && <div className='countdown-wrap'>
                        <div id="countdown" className="countdown">
                            <div className='countdown-content-block'>
                                <div className='countdown_text_block'>
                                    <p className='countdown-descr'>
                                    Buy now your ticket for $399
                                    </p>
                                    <p className='countdown-descr countdown-descr__small'>
                                    Price up to $499 in
                                    </p>
                                </div>
                                <CountDown futureDate={this.state.deadLine}></CountDown>
                                <a target='_blank' rel="noreferrer" className='countdown_btn' href='https://geekle.us/ticket/1635169206308x184771132711239680?page=software_architecture'>GET TICKET</a>
                            </div>
                            <img onClick={this.showCountdown} className='close_countdown' src='//s3.amazonaws.com/appforest_uf/f1628241276600x355310995010009720/Frame%2013.png' alt='' />
                        </div>
                    </div>}
                </div>}
            </React.Fragment>
        )
    }
}
