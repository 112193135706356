import React from 'react';
import './popup.css'

export default class Times extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
            vis: false,
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({vis: !this.state.vis});
    }

    render() {
        return (
            <>
                <button className='popup_btn' onClick={this.handleClick}>SHOW ABSTRACT</button>
                {this.state.vis && <div onClick={this.handleClick} className='popup' key={`popup-${this.props.key}`}>
                    <div className='popup_container'>
                        <h4 onClick={this} className='popup_title'>{this.props.title}</h4>
                        <p onClick={this} className='popup_abstract popup_abstract--mb'>{this.props.abstract}</p>
                        {this.props.bio && 
                        <>
                            <h4 onClick={this} className='popup_title'>More about speaker</h4>
                            <p onClick={this} className='popup_abstract'>{this.props.bio}</p>
                        </>}
                    </div>
                </div>}
            </>
        )
    }
}