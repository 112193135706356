import React from 'react';
import './Partners.css';
import Aigents from "../../img/Partners/Aigents.png";
import DNA325 from "../../img/Partners/DNA325.png";
import Barcelona from "../../img/Partners/Domain-Driven Design Barcelona.png";
import Packt from "../../img/Partners/Packt.png";
import Education from "../../img/Partners/IT Education Academy.png";
import lafinstitute from "../../img/Partners/lafinstitute.png";
import guid from "../../img/Partners/guid.png";
import qubitlabs from "../../img/Partners/qubitlabs.png";
import swissdev from "../../img/Partners/swissdev.png";
import Apiumhub from "../../img/Partners/Apiumhub.png";

const Partners = (props) => {
    const state = {
        Partners_item: [ 
            {
                img: Aigents,
                link: 'https://aigents.co/',
            },
            {
                img: DNA325,
                link: 'https://dna325.com/',
            },
            {
                img: Packt,
                link: 'https://www.packtpub.com',
            },
            {
                img: Barcelona,
                link: 'https://www.dddbcn.org',
            },
            {
                img: Education,
                link: 'https://itea.ua/?utm_source=sitepartners&utm_medium=geekle&utm_campaign=infopart',
            },
            {
                img: lafinstitute,
                link: 'https://lafinstitute.org/',
            },
            {
                img: guid,
                link: 'https://guid.com.ua/en/',
            },
            {
                img: qubitlabs,
                link: 'https://qubit-labs.com/',
            },
            {
                img: swissdev,
                link: 'https://swissdevjobs.ch/jobs/Architect/all',
            },
            {
                img: Apiumhub,
                link: 'https://apiumhub.com/',
            },
      ],
    };

    const renderPartners_item = (item, i) => {
        return (
            <a href={item.link} target='_blank' rel="noreferrer" className="logo_item" key={`Numbers_item-${i}`}>
                <img src={item.img} alt="Partners logo" />
            </a>
        );
    };

    return (
        <div className="partners" id='partners'>
            <h3>Partners</h3>
            <div className="container">
                {state.Partners_item.map((item, i) => renderPartners_item(item, i))}
            </div>
        </div>
    );
};

export default Partners;