import React from 'react';
import './PC.css';
import Carlos from '../../img/PC/Carlos.png'
import Otavio from '../../img/PC/Otavio.png'
import Ritchson from '../../img/PC/Ritchson.png'
import Md from '../../img/PC/Md.png'
import Michał from '../../img/PC/Michał.png'
import Nick from '../../img/PC/Nick.png'
import Michel from '../../img/PC/Michel.png'
import Mustafa from '../../img/PC/Mustafa.png'
import Vlad from '../../img/PC/Vlad.png'
import Jeremiah from '../../img/PC/Jeremiah.png'
import Jesus from '../../img/PC/Jesus.png'
import Bura from '../../img/PC/Bura.png'
import Loverde from '../../img/PC/Loverde.png'
import Gaëtan from '../../img/PC/Gaëtan.png'

export default class PC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pc: [
        {
          photo: Bura,
          name: 'Juriy Bura',
          position: 'Head of Solution Architecture & Technology Consulting',
          ln: 'https://www.linkedin.com/in/juriy/',
        },
        {
          photo: Otavio,
          name: 'Otavio Santana',
          position: 'Distinguished Software Engineer',
          tw: 'https://twitter.com/otaviojava',
          ln: 'https://www.linkedin.com/in/otaviojava/',
          github: 'https://github.com/otaviojava',
        },
        {
          photo: Carlos,
          name: 'Carlos Arturo Quiroga Quiroga',
          position: 'Digital Architecture Corporate Leader',
          ln: 'https://linkedin.com/in/carlosarturoq/',
        },
        {
          photo: Ritchson,
          name: 'Michael Ritchson',
          position: 'Digital solutions architect',
          ln: 'https://www.linkedin.com/in/michael-ritchson/',
        },
        {
          photo: Md,
          name: 'Md Kamaruzzaman',
          position: 'Lead Solution Architect',
          website: 'https://md-kamaruzzaman.medium.com/',
        },
        {
          photo: Michał,
          name: 'Michał Matłoka',
          position: 'Architect',
          tw: 'https://twitter.com/mmatloka',
        },
        {
          photo: Nick,
          name: 'Nick Beaugeard',
          position: 'Chief Software Architect',
          tw: 'https://twitter.com/nickbeau',
          ln: 'https://www.linkedin.com/in/nickbeaugeard',
          github: 'https://github.com/nickbeau',
        },
        {
          photo: Michel,
          name: 'Michel Ruffieux',
          position: 'Lead Software Engineer',
          tw: 'https://twitter.com/codepawn',
          ln: 'https://www.linkedin.com/in/mruffieux/',
        },
        {
          photo: Mustafa,
          name: 'Mustafa Toroman',
          position: 'Solution Architect',
          tw: 'https://twitter.com/toromust',
        },
        {
          photo: Vlad,
          name: 'Vlad Khononov',
          position: 'Senior Cloud Architect',
          tw: 'https://twitter.com/vladikk',
        },
        {
          photo: Jeremiah,
          name: 'Jeremiah Peschka',
          position: 'Staff Software Developer',
          ln: 'https://www.linkedin.com/in/jeremiahpeschka',
        },
        {
          photo: Jesus,
          name: 'Jesus Cuesta Arza',
          position: 'Senior Specialist Lead',
          ln: 'https://www.linkedin.com/in/jesuscuesta/',
        },
        {
          photo: Loverde,
          name: 'Olivier Loverde',
          position: 'CTO',
          ln: 'https://www.linkedin.com/in/loverdeolivier/',
          tw: 'https://twitter.com/loverdeolivier',
        },
        {
          photo: Gaëtan,
          name: 'Gaëtan Bloch',
          position: 'Technical Architect',
          website: 'https://gbloch.tech/',
        },
      ]
    }
  }

  renderPC(item, i) {
    return (
      <div className="pc_item" key={`team-${i}`}>
        <div className="pc_img_block">
          <img className="team_img" src={item.photo} alt={item.name} />
        </div>
        <div className="pc_info">
          <p className="pc_name">{item.name}</p>
          <p className="pc_position">{item.position}</p>
          <div className="pc_links">
            {item.fb && <a className="pc_link social_fb" target='_blank' rel="noreferrer" href={item.fb}></a>}
            {item.ln && <a className="pc_link social_ln" target='_blank' rel="noreferrer" href={item.ln}></a>}
            {item.tw && <a className="pc_link social_tw" target='_blank' rel="noreferrer" href={item.tw}></a>}
            {item.github && <a className="pc_link social_github" target='_blank' rel="noreferrer" href={item.github}></a>}
            {item.website && <a className="pc_link social_website" target='_blank' rel="noreferrer" href={item.website}></a>}
          </div>
        </div>
      </div>
    )
  }

  render() {
      return (
          <div className="pc" id='pc'>
              <h3>Program committee</h3>
              <div className='container team_wrap'>
                  <div className="pc_block">
                    {this.state.pc.map((item, i) => this.renderPC(item, i))}
                  </div>
              </div>
          </div>
      )
  }
}