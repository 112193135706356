import React from 'react';
import './Mission.css';
import MissionImg from '../../img/Mission/Mission_img.png'

export default class Mission extends React.Component {
    render() {
        return (
            <div className="mission">
                <h3>Our mission</h3>
                <div className='container mission_wrap'>
                    <div className="mission_img_block mission_item">
                        <img className="mission_img" src={MissionImg} alt="" />
                    </div>
                    <div className="mission_text_block mission_item">
                        <p className="mission_text">While offline events are temporarily stopped, Geekle never stops and is running the online Worldwide Software Architecture Summit on March 1-3, 2022. Our speakers are leading experts from all over the world who are ready to share what challenges Architect experts face in their work.</p>
                        <p className="mission_text">Geekle has the unique experience to gather huge tech summits with 10'000+ attendees in different tech domains. Now we hope to make something the world has never seen before for the World Architecture Community.</p>
                    </div>
                </div>
            </div>
        )
    }
}