import React from "react";
import "./askCompany.css";

const AskCompany = () => {
  return (
    <div className="askCompany">
      <iframe className='iframe_askcompany'
        src="https://geekle.us/0a_architecture?color1=%23171716&color2=%2361DBFB&color3=%23FBFBFB&event=wsas22"
        width="100%"
        height="480"
        align="center"
        title="ask_company_frame"
      >
        Ваш браузер не поддерживает плавающие фреймы!
      </iframe>
    </div>
  );
};
export default AskCompany;
