import React from 'react';
import './Audience.css';
import Marquee from 'react-double-marquee';

const Audience = (props) => {
    return (
        <div className="audience">
            <h3>Audience</h3>
            <div className='audience_wrap container--full' style={{
                width: '100%',
                whiteSpace: 'nowrap',
            }}>
                <Marquee delay={0} childMargin={0}>
                    <span className="green"> CTOs</span><span>CTOs</span><span className="green">Architects</span><span>Architects</span><span className="green"> CTOs</span><span>CTOs</span><span className="green">Architects</span><span>Architects</span><span className="green"> CTOs</span><span>CTOs</span><span className="green">Architects</span><span>Architects</span><span className="green"> CTOs</span><span>CTOs</span><span className="green">Architects</span><span>Architects</span>
                </Marquee>
                <Marquee delay={0} childMargin={0} direction='left'>
                    <span className="green">Software Developers</span><span>Software Developers</span><span className="green">Software Engineers</span><span>Software Engineers</span><span className="green">Software Developers</span><span>Software Developers</span><span className="green">Software Engineers</span><span>Software Engineers</span><span className="green">Software Developers</span><span>Software Developers</span><span className="green">Software Engineers</span><span>Software Engineers</span>
                </Marquee>
            </div>
        </div>
    );
};

export default Audience;