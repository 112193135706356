import React from 'react';
import './VideoPresent.css';
import Popup from './Popup/Popup.jsx'

export default class VideoPresent extends React.Component {
  renderVideoPresent(item, i) {
    return (
      <div className="agenda_item">
          <div className="agenda_info">
            <p className="agenda_time">{item.time}</p>
            <p className="agenda_topic">"{item.title}"</p>
            <img className="agenda_logo" src={item.logo} alt="" />
          </div>
          <div className="agenda_bio">
            <img className="agenda_photo" src={item.photo} alt="" />
            <div>
              <p className="agenda_name">{item.name}</p>
              <p className="agenda_position">{item.position}</p>
            <div className='agenda_social'>
              {item.tw && <a className='social_link social_tw' target='_blank' rel="noreferrer" href={item.tw} />}
              {item.github && <a className='social_link social_github' target='_blank' rel="noreferrer" href={item.github} />}
              {item.ln && <a className='social_link social_ln' target='_blank' rel="noreferrer" href={item.ln} />}
              {item.fb && <a className='social_link social_fb' target='_blank' rel="noreferrer" href={item.fb} />}
              {item.website && <a className='social_link social_website' target='_blank' rel="noreferrer" href={item.website} />}
              {item.inst && <a className='social_link social_inst' target='_blank' rel="noreferrer" href={item.inst} />}
            </div>
            </div>
          </div>
          <Popup abstract={item.abstract} title={item.title} bio={item.bio} key={i} />
      </div>
    )
  }

  render() {
      return (
        <div className="agenda_block">
          {this.props.speakers.map((item, i) => <React.Fragment key={`agenda_item-${i}`}>{this.renderVideoPresent(item, i)}</React.Fragment>)}
        </div>
      )
  }
}