import './main.css';
import { Link } from "react-scroll";
import React from 'react';
import {InlineShareButtons} from 'sharethis-reactjs';

export default class Main extends React.Component {
    render() {
        return (
            <div className='main_wrap'>
                <div className='container main_block'>
                    <div className='main_content'>
                        <h1 className='main_title'>WORLDWIDE SOFTWARE ARCHITECTURE SUMMIT'22</h1>
                        <p className='main_descr main_descr-data'>March 1-3, 2022</p>
                        <p className='main_descr'>Online, 10 am GMT</p>
                        <div className='main_btns_wrap'>
                            <Link
                            activeClass="active"
                            to='tickets'
                            spy={true}
                            smooth={true}
                            offset={80}
                            className='main_btn main_btn--first'
                            >
                            GET TICKET
                            </Link>
                            <InlineShareButtons
                                className='main_button main_btn--share'
                                config={{
                                alignment: 'center',  // alignment of buttons (left, center, right)
                                color: 'transparent',      // set the color of buttons (social, white)
                                enabled: true,        // show/hide buttons (true, false)
                                font_size: 16,        // font size for the buttons
                                labels: 'cta',        // button labels (cta, counts, null)
                                language: 'en',       // which language to use (see LANGUAGES)
                                networks: [           // which networks to include (see SHARING NETWORKS)
                                    'sharethis'
                                ],
                                padding: 12,          // padding within buttons (INTEGER)
                                radius: 0,            // the corner radius on each button (INTEGER)
                                size: 49,             // the size of each button (INTEGER)

                                // OPTIONAL PARAMETERS
                                url: 'https://events.geekle.us/software_architecture/?utm_source=website&utm_medium=sharelink', // (defaults to current url)
                                description: 'Anything, that you have to know about Software Architecture in 2022',       // (defaults to og:description or twitter:description)
                                title: `Worldwide Software Architecture Summit 22`,            // (defaults to og:title or twitter:title)
                                }}
                            />
                        </div>
                        {/* <div className='main_btns_wrap main_btns_wrap--next'>
                            <a className='main_btn main_btn--next main_btn--share' target='_blank' rel='noreffer' href="https://events.geekle.us/wsas4/">Next event</a>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}