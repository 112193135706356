import React from 'react';
import './Reviews.css';
import Erwin from '../../img/reviews/Erwin.png'
import Gustavo from '../../img/reviews/Gustavo.png';
import Sarasola from '../../img/reviews/Sarasola.png';
import Prabu from '../../img/reviews/Prabu.png';

export default class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [
        {
            id: 1,
            photo: Sarasola,
            name: 'Lucía Sarasola',
            ln: 'https://www.linkedin.com/in/luciasarasola/?originalSubdomain=ar',
            title: `Attending made me realize I do apply architecture to my day to day work but never knew how much. Also introduced me to new knowledge about the industry. One of the most useful webinars I've ever attended. So lucky I've run into your call. Thanks for all the hard work it surely took you all to accomplish this. Amazing efforts. Keep rocking!`,
        },
        {
            id: 2,
            photo: Erwin,
            name: 'Erwin Abelardo Pacheco',
            title: `I am grateful to hear from industry's practitioners, best practices, correct approaches thru fundamentals (various architecture concepts and case-based applications), scalability thru available microservices and options to revert or migrate, and the thought of realizing business solutions.`,
        },
        {
            id: 3,
            photo: Gustavo,
            name: 'Gustavo Dalla Nora',
            ln: 'https://www.linkedin.com/in/gustavodallanora/',
            title: `It was a very rich learning experience to be able to see what people with Software Architecture roles in companies around the world are doing to talke Software Architecture challenges in modern industry.`,
        },
        {
            id: 4,
            photo: Prabu,
            name: 'Prabu Varadharajalu Sivakumar',
            title: `Thoroughly blessed to be a part of this event. Had great opportunity to learn from Industry Experts. Though there are technical glitches, the show went on. There was always a back up process and very thoughtful insights. I’ve enrolled to all the 20 sessions in 2021. Can’t wait to learn from every track.`,
        },
      ]
    }
  }

  renderReviews(item, i) {
    return (
      <div className="reviews_item" key={`workshops-${i}`}>
        <div className='reviews_item-info_wrap'>
            <div className='reviews_item-info' style={{ backgroundImage: `url(${item.photo})` }}>
                <p className="reviews_item_name">{item.name}</p>
                <p className="reviews_item_position">{item.position}</p>
            </div>
            <div className='reviews_item-content'>
                <p className='reviews_title'>{item.title}</p>
            </div>
        </div>
      </div>
    )
  }

  render() {
      return (
          <div className="reviews">
              <h3>What people say</h3>
              <div className='container reviews_wrap'>
                <div className="review_block">
                  {this.state.reviews.map((item, i) => this.renderReviews(item, i))}
                </div>
              </div> 
          </div>
      )
  }
}