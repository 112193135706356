import React from 'react';
import './Speakers.css';
import VideoPresent from './VideoPresent/VideoPresent';

const Spaekers = (props) => {
    return (
        <div className="speakers" id='speakers'>
            <h3>Our speakers</h3>
            <VideoPresent />
        </div>
    );
};

export default Spaekers;