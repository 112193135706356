import React from "react";
import "./Track.css";
import Cartificate from "../../../img/Packages/cartificate.png";
import Micro from "../../../img/Packages/micro.png";
import Play from "../../../img/Packages/play.png";
import QA from "../../../img/Packages/qa.png";
import Slack from "../../../img/Packages/slack.png";

const Track = (props) => {
    // const utm = Object.fromEntries((window.location.href.match(/(?:utm_).+?=[^&]*/g) || []).map(n => n.split('=')));
    const pageLink = window.location.href.toString(); 
    const indexUtm = pageLink.indexOf('?');
    const utm = pageLink.slice(indexUtm+1);

    return (
    <div className={`track ${props.track.trackDesc === '1' ? 'active' : ''}`}>
        <p className='trackTitle'>{props.track.trackTitle}</p>
        <p className='trackSubtitle'>{props.track.trackSubtitle}</p>
        {props.track.trackDesc && 
        <>
            <div className='trackPrice trackPrice--active'>
                <div>
                    <p className='packages_price'>$499</p>
                    <p className='packages_price--desc'></p>
                </div> 
                {/* <div className='packages_price--full_block'>
                <p className='packages_price--full'>$499</p>
                <p className='packages_price--full_desc'>full price</p>
                </div> */}
            </div>
        </>}
        {!props.track.trackDesc && <p className={`trackPrice ${props.track.trackDesc === '1' ? 'active' : ''}`}>{props.track.trackPrice}</p>}
        <div className={`trackDesc ${props.track.trackDesc === '1' ? 'active' : ''}`}>
            <div className='trackDesc_item trackDesc_second'>
                <img src={Micro} alt="track_micro" />
                <p>Career Track Access</p>
            </div>
            <div className='trackDesc_item trackDesc_second'>
                <img src={QA} alt="track_qa" />
                <p>{props.track.trackQA ? props.track.trackQA : 'Live Q&A panels'}</p>
            </div>
            <div className='trackDesc_item trackDesc_first'>
                <img src={Micro} alt="track_micro" />
                <p>{!props.track.trackDesc && 'No ' }Pro Track Access</p>
            </div>
            <div className='trackDesc_item trackDesc_first'>
                <img src={Play} alt="track_play" />
                <p>{props.track.trackFull ? props.track.trackFull : `${!props.track.trackDesc && 'No ' } On-demand recordings of both tracks`}</p>
            </div>
            <div className='trackDesc_item trackDesc_fourth'>
                <img src={Slack} alt="track_slack" />
                <p>{!props.track.trackDesc && 'No ' }Slack membership</p>
            </div>
            <div className='trackDesc_item trackDesc_fifth'>
                <img src={Cartificate} alt="track_cartificate" />
                <p>{!props.track.trackDesc && 'No ' }Certificate of attendance</p>
            </div>
        </div>
        <a className='trackButton' target='_blank' rel='noreffer' href={`${props.track.trackLink}?page=software_architecture${indexUtm !== -1 ? `&${utm}` : ''}`}>{props.track.trackButton}</a>
        {/* <a className='trackButton' target='_blank' rel="noreferrer" href={`${props.track.trackLink}?page=software_architecture${(utm.utm_campaign ? `&utm_campaign=${utm.utm_campaign}` : '')}${(utm.utm_source ? `&utm_source=${utm.utm_source}` : '')}${(utm.utm_medium ? `&utm_medium=${utm.utm_medium}` : '')}${(utm.utm_term ? `&utm_term=${utm.utm_term}` : '')}${(utm.utm_content ? `&utm_content=${utm.utm_content}` : '')}`}>{props.track.trackButton}</a> */}
    </div>
  );
};

export default Track;