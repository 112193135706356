import React from 'react';
import './Numbers.css';


const Numbers = (props) => {
    const state = {
        Numbers_item: [
        {
            numb: '10 000+',
            text: 'Registrations expected',
        },
        {
            numb: '40+',
            text: 'Speakers expected',
        },
        {
            numb: '28 hours',
            text: 'of Tech Talks',
        },
      ],
    };

    const renderNumbers_item = (item, i) => {
        return (
            <div className="numbers_item" key={`Numbers_item-${i}`}>
                <p className='numbers_item-numb'>{item.numb}</p>
                <div className='numbers_hr'></div>
                <p className='numbers_item-text'>{item.text}</p>
            </div>
        );
    };

    return (
        <div className="numbers">
            <div className="container">
                {state.Numbers_item.map((item, i) => renderNumbers_item(item, i))}
            </div>
        </div>
    );
};

export default Numbers;