import React from 'react';
import './Agenda.css';
import VideoPresent from './VideoPresent/VideoPresent';
import chats from '../../img/agenda/chats.png'
import Neimer from '../../img/agenda/Neimer.png'
import Google from '../../img/agenda/Google.png'
import Huang from '../../img/agenda/Huang.png'
import Khan from '../../img/agenda/Khan.png'
import Lysenko from '../../img/agenda/Lysenko.png'
import Microsoft from '../../img/agenda/Microsoft.png'

export default class Agenda extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [
              {
                  id: 1,
                  time: '10:00',
                  photo: Khan,
                  name: 'Asif Khan',
                  position: 'Cloud Solution Architect',
                  ln: 'https://www.linkedin.com/in/asif-khan-34458b6/',
                  tw: 'https://twitter.com/khanasif1',
                  github: 'https://github.com/khanasif1',
                  logo: Microsoft,
                  title: 'Scaling System Integration with Cloud',
                  abstract: `System integration is required by every organization - small/medium or large enterprise. Historically point to point integrations were built to accomplish data sharing between systems. This was limited with scale, complex to enhance, and hard to manage.
                  1. In this talk I will share how I helped large enterprises to small corporates in building integrations platforms that are scalable, extendable, and low touch to manage.
                  2. I have been in the Integration space for more than 7 years now and overall worked for more than 15 years building applications and integration platforms
                  
                  1. Audience will learn challenges with conventional integration 
                  2. What are the different integration patterns used 
                  3. How Azure Cloud can be used to scale any integration solution
                  4. How to design an Azure cloud-based scalable integration solution
                  5. What components in Azure can be used for scaling
                  6. What are the best practices to monitor and alert integration failures`,
              },
              {
                  id: 2,
                  time: '10:30',
                  photo: Huang,
                  name: 'Shuzhi Huang',
                  position: 'Engineering Manager',
                  ln: 'https://www.linkedin.com/in/shuzhi-huang-89aa58a6/',
                  logo: Google,
                  title: 'Design a mobile app logging platform that scales for hundreds of engineers',
                  abstract: `Logging for a giant app is challenging. Besides the regular challenges of mobile network reliability, balancing data completeness vs performance, etc.; like a giant app you have to control the log growth sustainably, ensure data interoperability across logs from teams who probably even don't know each other, avoid the tragedy of the commons, reduce cognitive load for logging, controlling data quality...

                  The talk shares the experience of how my team designed a sustainable logging platform that is effective and scales. It will cover the principles, origins, evolvement, and some good practices. The talk will steer away from specific technical terms to avoid company internal details, but the concepts should be transferrable and are more important. Lessons learned from building an effective and sustainable data system for a giant mobile app.
                  Platform team (a concept from Team Topologies) in action.`,
              },
              {
                  id: 3,
                  time: '11:00',
                  photo: Lysenko,
                  name: 'Anastasiia Lysenko',
                  position: 'CTO',
                  ln: 'https://www.linkedin.com/in/anastasiia-lysenko-81435283/',
                  logo: chats,
                  title: 'GraphQL: change for the best',
                  abstract: `I’d like to share with you real-life code and schema examples of how one can successfully integrate GraphQL into the backend and how to optimize the result. At the moment it’s hardly unlikely to meet a web developer, who doesn’t know or doesn’t hear a thing about GraphQL. 
                  And it’s understandable: more and more developers choose to begin or continue to use it in their projects due to various advantages. Such as single end-point, extendable hierarchy, strict types, flexibility among others. But the integration part is the hardest here. 
                  
                  In this topic you will get in detail is an example of a GraphQL integration into a project. GraphQL resolver example, useful optimization tips, real-life schema examples`,
              },
              {
                  id: 4,
                  time: '11:30',
                  photo: Neimer,
                  name: 'Slavik Neimer',
                  position: 'Principal Software Engineering Manager',
                  ln: 'https://www.linkedin.com/in/slavik-neimer/',
                  logo: Microsoft,
                  title: 'Big Data Analytics and Azure Data Explorer (Kusto)',
                  abstract: `What is Big Data? Why does it get more and more popular?
                  Big Data plays a huge role in everyone’s life and allows the digital transformation that’s taking place in all industries. It’s used to provide better cybersecurity solutions, better healthcare, more efficient manufacturing processes, numerous IoT scenarios, and much more.
                  In this session, you'll get to know what Big Data is (hint: it's much more than a buzzword), see practical use-cases and examples, and learn how you can benefit from it.
                  
                  I intend to show real use-cases and examples, by actually running queries on Big Data. 
                  I’m going to run the queries on Azure Data Explorer (Kusto), in which I’m an engineering manager, but this is not going to be a product demo or a sales talk – it’s just going to be a tool for me to make this session interesting and lively.`,
              },
          ],
        }
      }

    render() {
        return (
            <div className="agenda" id='agenda'>
                <div className='container agenda'>
                    <h3 className="agenda_title">Agenda</h3>
                    <p className="agenda_undertitle">Time zone 0 UTC</p>
                    <p className="agenda_undertitle">March 1 09:50 - 23:30</p>
                    <p className="agenda_day">Career Track</p>
                    <VideoPresent speakers={this.state.list}/>
                    <a className='full_agenda_btn' href='https://geekle.us/schedule/wsas22?track=1637857289898x975070460842868700' target='_blank' rel="noreferrer">SHOW FULL AGENDA</a>
                </div>
            </div>
        );
    }
};