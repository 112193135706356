import React from 'react';
import './team.css';
import Ed from '../../img/Team/Ed.png';
import Ksyusha from '../../img/Team/Ksyusha.png';
import Nik from '../../img/Team/Nik.png';
import Pavel from '../../img/Team/Pavel.png';

export default class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: [
        {
          photo: Ksyusha,
          name: 'Ksenia Shalun',
          position: 'Community manager',
          mail: 'mailto:ksenia@geekle.us',
          ln: 'https://www.linkedin.com/in/kseniasha/',
        },
        {
          photo: Ed,
          name: 'Ed Nedin',
          position: 'Chief Executive Officer Geekle',
          ln: 'https://www.linkedin.com/in/ed-nedin-b05a95212/',
        },
        {
          photo: Nik,
          name: 'Nik Petrov',
          position: 'Content Production Lead',
          ln: 'https://www.linkedin.com/in/nik-petrov-828b4119b/',
        },
        {
          photo: Pavel,
          name: 'Pavlo Pankratov',
          position: 'Partner Success Manager',
          mail: 'mailto:pp@geekle.us',
          ln: 'https://www.linkedin.com/in/pavel-pankratov-039564101/',
        },
      ]
    }
  }

  renderTeam(item, i) {
    return (
      <div className="team_item" key={`team-${i}`}>
        <div className="team_img_block">
          <img className="team_img" src={item.photo} alt={item.name} />
        </div>
        <div className="team_info">
          <p className="team_name">{item.name}</p>
          <p className="team_position">{item.position}</p>
          <div className="team_links">
            {item.mail && <a className="team_link social_mail" target='_blank' rel="noreferrer" href={item.mail}></a>}
            {item.ln && <a className="team_link social_ln" target='_blank' rel="noreferrer" href={item.ln}></a>}
          </div>
        </div>
      </div>
    )
  }

  render() {
      return (
          <div className="ourTeam" id='team'>
              <h3>Our Team</h3>
              <div className='container team_wrap'>
                  <div className="team_block">
                    {this.state.team.map((item, i) => this.renderTeam(item, i))}
                  </div>
              </div>
          </div>
      )
  }
}