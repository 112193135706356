import React from "react";
import "./Packages.css";
import PackagesInfofrom from "../../store/PackagesInfo.js";
import PackagesChild from './Track/Track.jsx'

const Packages = () => {
  let packagesElements = PackagesInfofrom.map((PackagesItem, i) => {
    return <PackagesChild key={`track-${i}`} track={PackagesItem} />;
  });
  return (
    <div className="packages" id='tickets'>
        <h3>Packages</h3>
        <div className='container track_wrap'>
            {packagesElements}
        </div>
    </div>
  );
};
export default Packages;