import React, { useState } from "react";
import { Link } from "react-scroll";
import hamburger from "../../../img/burger.svg";
import OpenMenu from "./OpenMenu/OpenMenu.jsx";
import './Menu.css';

const Menu = (props) => {
  const [showText, setShowText] = useState(false);
  const onClick = () => setShowText(true);
  const onClickclose = () => setShowText(false);
  // const scrollToTop = () => {
  //   scroll.scrollToTop();
  // };

    return (
      <>
        <ul className='menu'>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='speakers'
              spy={true}
              smooth={true}
              offset={-94}
              >
                Speakers
            </Link>
          </li>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='tickets'
              spy={true}
              smooth={true}
              offset={80}
              >
              Tickets
            </Link>
          </li>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='agenda'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Agenda
            </Link>
          </li>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='workshops'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Workshops
            </Link>
          </li>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='sponsors'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Sponsors
            </Link>
          </li>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='partners'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Partners
            </Link>
          </li>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='pc'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Committee
            </Link>
          </li>
          <li className='menu_pc'>
              <Link
              activeClass="active"
              to='team'
              spy={true}
              smooth={true}
              offset={-94}
              >
              Team
            </Link>
          </li>
          <div className="mobile-menu">
          <div className="mobile-menu_img-block">
            <img alt='' src={hamburger} onClick={onClick} className="hamburgerMenu mobile-menu-img mobile-menu_img" />
          </div>
          <div>
            {showText ? (
              <OpenMenu close={onClickclose} />
            ) : null}
          </div>
        </div>
        </ul>
      </> 
    );
  }
        
export default Menu;