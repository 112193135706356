import React from 'react';
import './Become.css';
import Speaker from "../../img/Become/speaker.svg";
import Sponsor from "../../img/Become/sponsor.svg";
import Moderator from "../../img/Become/moderator.svg";

const Become = (props) => {
    const state = {
        Become_item: [ 
            {
                img: Speaker,
                title: 'Become a speaker',
                href: 'https://docs.google.com/forms/d/e/1FAIpQLSeY0oqjJEMJDVsSZaD1IYTY5zwa5P9XDNu2dpFYxWD4ZWzD3Q/viewform',
            },
            {
                img: Sponsor,
                title: 'Become a sponsor',
                href: 'https://docs.google.com/forms/d/e/1FAIpQLSfQWmA1VZscjaEbqFcQ_UCRfiO-fPrJWp_EI6qmBk7kuY3c_w/viewform',
                sponsorship: 'https://drive.google.com/file/d/1LiqqpA6P34LPUAiC28EicGe6RF33PLAv/view?usp=sharing',
            },
            {
                img: Moderator,
                title: 'Become a moderator',
                href: 'https://docs.google.com/forms/d/e/1FAIpQLSdwZJWJBZgt3KnpEo68Z_fDkkTa6a-Mv64_NvHuIi65yNC1Fg/viewform',
            },
      ],
    };

    const renderBecome_item = (item, i) => {
        return (
            <div className="become_item" key={`Numbers_item-${i}`}>
                <img src={item.img} alt="" />
                <p className='become_title'>{item.title}</p>
                <a className='become_button' target='_blank' rel="noreferrer" href={item.href}>Apply</a>
                {item.sponsorship && <a className='become_button become_button--sponsorship' target='_blank' rel="noreferrer" href={item.sponsorship}>Prospectus</a>}
            </div>
        );
    };

    return (
        <div className="become">
            <div className="container">
                {state.Become_item.map((item, i) => renderBecome_item(item, i))}
            </div>
        </div>
    );
};

export default Become;