import React from 'react';
import './Sponsors.css';
import site24 from "../../img/Sponsors/site24.png";


const Sponsors = (props) => { 
    const state = {
        Sponsors_item: [
            {
                img: site24,
                link: 'http://site24x7.com/',
            },
      ],
    };

    const renderSponsors_item = (item, i) => {
        return (
            <a href={item.link} target='_blank' rel="noreferrer" className="logo_item" key={`Numbers_item-${i}`}>
                <img src={item.img} alt="Sponsors logo" />
            </a>
        );
    };

    return (
        <div className="sponsors" id='sponsors'>
            <h3>Sponsors</h3>
            <div className="container">
                {state.Sponsors_item.map((item, i) => renderSponsors_item(item, i))}
            </div>
        </div>
    );
};

export default Sponsors;