import React from 'react';
import './Speakers from.css';
import Google from "../../img/SF/google.png";
import Microsoft from "../../img/SF/microsoft.png";
import AWS from "../../img/SF/AWS.png";
import Cyral from "../../img/SF/Cyral.png";
import Oracle from "../../img/SF/Oracle.png";
import PowerM from "../../img/SF/PowerM.png";
import RedHat from "../../img/SF/RedHat.png";
import Softerrific from "../../img/SF/Softerrific.png";
import SonicWall from "../../img/SF/SonicWall.png";
import WSO2 from "../../img/SF/WSO2.png";
import Xanadu from "../../img/SF/Xanadu.png";
import Zup from "../../img/SF/Zup.png";
import Intel from "../../img/SF/Intel.png";
import codescience from "../../img/SF/codescience.png";

const SpeakersFrom = (props) => { 
    const state = {
        Speakers_item: [
            {
                img: Intel,
            },
            {
                img: Microsoft,
            },
            {
                img: Google,
            },
            {
                img: Oracle,
            },
            {
                img: PowerM,
            },
            {
                img: RedHat,
            },
            {
                img: codescience,
            },
            {
                img: Softerrific,
            },
            {
                img: SonicWall,
            },
            {
                img: WSO2,
            },
            {
                img: Xanadu,
            },
            {
                img: Cyral,
            },
            {
                img: Zup,
            },
            {
                img: AWS,
            },
      ],
    };

    const renderSpeakers_item = (item, i) => {
        return (
            <div className="logo_item" key={`Numbers_item-${i}`}>
                <img src={item.img} alt="Speakers logo" />
            </div>
        );
    };

    return (
        <div className="speakers_from">
            <h3>Speakers in our community</h3>
            <div className="container">
                {state.Speakers_item.map((item, i) => renderSpeakers_item(item, i))}
            </div>
        </div>
    );
};

export default SpeakersFrom;