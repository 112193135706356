import React from "react";
import "./TagCloud.css";



const TagCloud = () => {
  return (
    <div className="whatsNew container--full">
      {/* <h3>Tag cloud</h3> */}
      <div className='container'>
        <div className="whatsNew-cloud">
          <p className="item-1">Event-Driven Architecture</p>
          <p className="item-2">Deployment</p>
          <p className="item-3">Open Application Model (OAM)</p>
          <p className="item-4">ML/AI</p>
          <p className="item-5">Design for sustainability</p>
          <p className="item-6">CQRS</p>
          <p className="item-7">Personal development</p>
          <p className="item-8">Micro frontends</p>
          <p className="item-9">Instruments</p>
          <p className="item-10">Design for portability</p>
          <p className="item-11">Service Mesh</p>
          <p className="item-12">Regulations and compliance / GDPR /</p>
          <p className="item-13">Policy as Code</p>
          <p className="item-14">Blockchain</p>
          <p className="item-15">Kafka + KSQL</p>
          <p className="item-16">HTTP/3 frameworks and realtime</p>
          <p className="item-17">RSocket & Reactive Streams</p>
          <p className="item-18">Dapr</p>
          <p className="item-19">gRPC</p>
        </div>
      </div>
    </div>
  );
};
export default TagCloud;
